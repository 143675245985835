import { useAtom, useAtomValue, atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useUserLogin } from 'hooks';
import { useRouteMatch } from 'react-router-dom';
import { IUser, IConference } from 'models';
import { useEffect } from 'react';

export const conferenceIdAtom = atomWithStorage<string>(
  'elanco_conference_id',
  '',
);
export const userAtom = atomWithStorage<IUser | null>('elanco_user', null);

export const redirectAtom = atom('dashboard');

export const userEmailAtom = atom((get) => {
  const user = get(userAtom);
  return user?.email;
});

export const userIdAtom = atom((get) => {
  const user = get(userAtom);
  return user?.user_id;
});

export const isAuthenticatedAtom = atom((get) => {
  const user = get(userAtom);
  return user !== null;
});

export const gamesScoreAtom = atom((get) => {
  const user = get(userAtom);
  const conferecenId = get(conferenceIdAtom);

  const conference = user?.user_conference?.find(
    (conference: IConference) => conference.conference_id === conferecenId,
  );

  return conference?.points || 0;
});

export const SessionAtom: React.FC = () => {
  const [, setConferenceId] = useAtom(conferenceIdAtom);
  const [, setUser] = useAtom(userAtom);
  const email = useAtomValue(userEmailAtom);
  const { data } = useUserLogin(email);

  let match = useRouteMatch([
    '*/conference/:conferenceId',
    '*/conference/:conferenceId/activity/:activityId',
    '*/schedule/:conferenceId',
  ]);

  // // @ts-ignore
  const isConferenceId = match?.params.hasOwnProperty('conferenceId');

  useEffect(() => {
    if (isConferenceId) {
      // @ts-ignore
      setConferenceId(match?.params.conferenceId);
    }
  }, [setConferenceId, isConferenceId, match?.params]);

  useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data, setUser]);

  return null;
};

import { useEffect } from 'react';
import * as confetti from 'canvas-confetti';
import './Confetti.scss';

interface ConfettiProps {
  onComplete?: Function;
}

let canvasRef: HTMLCanvasElement | null;
let confettiCanvas: Function;

const showConfetti = (callback?: Function) => {
  confettiCanvas({
    particleCount: 37,
    angle: 60,
    spread: 55,
    origin: { x: 0, y: 1 },
    colors: ['#0072CE', '#FFFFFF', '#0EC3A4'],
  });

  confettiCanvas({
    particleCount: 17,
    angle: 120,
    spread: 55,
    origin: { x: 1, y: 1 },
    colors: ['#0072CE', '#FFFFFF', '#0EC3A4'],
  });
  
  callback && callback();
};

const Confetti: React.FC<ConfettiProps> = () => {

  useEffect(() => {
    confettiCanvas = confetti.create(canvasRef, {
      resize: true,
      useWorker: true,
    });

    setTimeout(() => {
      showConfetti();
    });
  }, []);

  return (
    <div className="elanco-confetti">
      <canvas
        ref={(node) => {
          canvasRef = node;
        }}></canvas>
    </div>
  );
};

export default Confetti;

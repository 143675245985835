import { IonItem, IonLabel, IonCheckbox } from '@ionic/react';
import classname from 'classname';

interface QuestionsItemProps {
  answeredId: string,
  id: string,
  answer: string;
  handleClick: any;
  isCorrect: boolean;
  disabled: boolean;
}

const QuestionsItem: React.FC<QuestionsItemProps> = ({
  answeredId,
  id,
  answer,
  handleClick,
  isCorrect,
  disabled,
}) => {
  const classList = classname({
    'elanco--questionsItem': true,
    'mb-5': true,
    'is-wrong': answeredId === id && !isCorrect,
    'is-correct': answeredId === id && isCorrect,
    'is-disabled': disabled,
  });

  return (
    <div className={classList}>
      <IonItem
        onClick={() => !disabled && handleClick(id, isCorrect)}
        className="text-small">
        <IonLabel>{answer}</IonLabel>
        <IonCheckbox
          slot="end"
          checked={answeredId === id}
          value=""></IonCheckbox>
      </IonItem>
    </div>
  );
};

export default QuestionsItem;

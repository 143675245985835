import { IonSkeletonText } from '@ionic/react';

const PlaceholderShippingAddressForm: React.FC = () => (
  <>
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '53px', marginBottom: '10px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px', marginBottom: '10px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px', marginBottom: '10px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px', marginBottom: '10px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px', marginBottom: '20px' }}
    />
  </>
);

export default PlaceholderShippingAddressForm;

import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  IonText,
  IonItem,
  IonLabel,
  IonButton,
  IonRadio,
  useIonRouter,
  IonRadioGroup,
  useIonAlert,
} from '@ionic/react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import ControlledCheckbox from 'components/ControlledCheckbox/ControlledCheckbox';
import ProductItems from './ProductItems';
import { postPlaceOrder } from 'global';

const PlaceOrdenForm: React.FC = () => {
  const [modal] = useIonAlert();
  const ionRouter = useIonRouter();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const [products, setProducts] = useState([1]);

  const resetForm = () => {
    window.location.reload();
  };

  const onSubmit = async (data: any) => {
    // format product items
    const products = [];

    for (const key in data) {
      if (key.includes('product_name')) {
        const quantityKey = key.replace('product_name', 'quantity_value');
        const quantity = data[quantityKey];
        products.push({
          name: data[key],
          quantity: quantity,
        });
      }
    }

    const formData = {
      phone_number: data.phone_number,
      preferred_order: data.preferred_order,
      representative: data.representative,
      products,
    };

    const res = await postPlaceOrder(formData);
    if (res?.ok) {
      modal({
        cssClass: 'elanco--alert elanco--alert-order',
        header: 'Order placed!',
        message: `<p class="ion-text-left">Your sales rep will be in touch 
        after the show to finalize the details of your order.</p>`,
        buttons: [
          {
            text: 'Back to home',
            cssClass: 'button-outline',
            handler: () => ionRouter.push('/dashboard', 'forward', 'replace'),
          },
          { text: 'Place Another Order', handler: () => resetForm() },
        ],
      });
    }
  };

  const newNewProduct = () => {
    setProducts([...products, products.length + 1]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="elanco--form">
        <div className="elanco--item">
          <label id="phone_number">
            1. Practice Phone Number <IonText color="danger">*</IonText>
          </label>
          <input
            type="text"
            {...register('phone_number', { required: true })}
          />
          {errors.phone_number && <ErrorMessage />}
        </div>

        <ProductItems
          register={register}
          products={products}
          newNewProduct={newNewProduct}
          errors={errors}
          index={1}
        />

        {/* Radio Buttons */}
        <div className="elanco--item">
          <label id="preferred_order">
            3. What is your preferred way to order?{' '}
            <IonText color="danger">*</IonText>
          </label>

          <Controller
            name="preferred_order"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, ref } }) => (
              <IonRadioGroup
                className="elanco--radio-group"
                slot="start"
                onIonChange={(e) => onChange(e.detail.value)}
                ref={ref}>
                <IonItem class="">
                  <IonLabel color="white">Covetrus</IonLabel>
                  <IonRadio slot="start" value="covetrus"></IonRadio>
                </IonItem>

                <IonItem>
                  <IonLabel color="white">Midwest</IonLabel>
                  <IonRadio slot="start" value="midwest"></IonRadio>
                </IonItem>

                <IonItem>
                  <IonLabel color="white">MWI</IonLabel>
                  <IonRadio slot="start" value="mwi"></IonRadio>
                </IonItem>
                <IonItem>
                  <IonLabel color="white">Patterson</IonLabel>
                  <IonRadio slot="start" value="patterson"></IonRadio>
                </IonItem>
              </IonRadioGroup>
            )}
          />

          {errors.preferred_way_order && <ErrorMessage />}
        </div>

        {/* 4. Who is helping you place this order?  */}
        <div className="elanco--item">
          <label id="representative">
            4. Who is helping you place this order?{' '}
            <IonText color="danger">*</IonText>
          </label>
          <input
            type="text"
            {...register('representative', { required: true })}
          />
          {errors.representative && <ErrorMessage />}
        </div>

        {/* 5. Order Placement Acknowledgment *   */}
        <div className="elanco--item">
          <label id="consent">
            5. Order Placement Acknowledgment{' '}
            <IonText color="danger">*</IonText>
          </label>

          <IonItem className="padding-start-0">
            <IonText className="ion-text-wrap" color="white">
              By submitting this form, you are agreeing to place an order for
              the product in the quantities specified above.
            </IonText>
            <ControlledCheckbox
              name="consent"
              control={control}
              required={true}
            />
          </IonItem>
          {errors.consent && <ErrorMessage />}
        </div>
      </div>

      <div className="ion-text-center pt-5 pb-5">
        <IonButton
          type="submit"
          strong
          {...(!isValid ? { disabled: true } : {})}>
          Submit
        </IonButton>
      </div>
    </form>
  );
};

export default PlaceOrdenForm;

import useSWR from 'swr';
import { useIonAlert } from '@ionic/react';
import { CONFIG_API_URL } from 'global/config';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { toast } from 'global';
import { usePriceVsDonations } from './usePriceVsDonations';

export function useGameRules() {
  const [modal] = useIonAlert();
  const priceVsDonation = usePriceVsDonations();
  const url = `${CONFIG_API_URL}/contentful/game-rules`;
  const { data, error } = useSWR<any>(url);

  const header = priceVsDonation.isLoading
    ? 'Loading...'
    : `Learn & ${
        priceVsDonation.value ? 'Earn' : 'Give'
      } activity official rules`;

  const showGameRulesModal = () => {
    if (!error) {
      modal({
        cssClass: ['elanco--alert', 'elanco--alert--rules'],
        header: header,
        message: documentToHtmlString(data),
        buttons: [
          {
            text: 'Close',
            cssClass: 'button-outline',
            handler: () => console.log('close modal'),
          },
        ],
        onDidDismiss: () => console.log('did dismiss'),
      });
    } else {
      toast('Error loading game rules. Please try again later.', 'danger');
    }
  };

  return {
    showGameRulesModal,
    isError: error,
  };
}

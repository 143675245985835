import { toastController } from '@ionic/core';

export async function toast(message: string, color = 'dark', duration = 2000) {
  const toast = await toastController.create({
    color,
    duration,
    message,
  });

  await toast.present();
}

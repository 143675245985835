import useSWR from 'swr';
import { CONFIG_API_URL } from 'global/config';
import { IProductMain } from '../models/ProductMain';

// TODO reponse type
export function useProducts(productCategoryId: string) {
  const url = `${CONFIG_API_URL}/contentful/product-categories/${productCategoryId}`;
  const { data, error } = useSWR<IProductMain, any>(url);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

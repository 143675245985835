import './Layout.scss';
import {
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonMenuButton,
  IonContent,
} from '@ionic/react';
import Copyright from 'components/Copyright/Copyright';

import { IconLogo } from 'icons';

interface LayoutProps {
  color?: string;
  backButton?: boolean;
  menuButton?: boolean;
  copyright?: string;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  backButton = true,
  menuButton = true,
  copyright,
}) => {
  return (
    <IonPage>
      <IonToolbar>
        {backButton && (
          <IonButtons slot="start">
            <IonBackButton color="white" defaultHref="/dashboard" />
          </IonButtons>
        )}
        {menuButton && (
          <IonButtons slot="end">
            <IonMenuButton color="white"></IonMenuButton>
          </IonButtons>
        )}
      </IonToolbar>
      <div className="elanco--logo">
        <IconLogo />
      </div>
      <IonContent className="ion-padding">
        <div className="ion-content--bottom">
          {children}
          {copyright && <Copyright text={copyright} />}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Layout;

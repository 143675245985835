import './Register.scss';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IonText, useIonAlert, useIonRouter, IonLoading } from '@ionic/react';
import { useSetAtom, useAtomValue } from 'jotai';
import { userAtom, redirectAtom } from 'atoms';
import { postUser, getRouteUrl } from 'global';
import { IUser } from 'models';
import Layout from 'components/Layout/Layout';
import RegisterForm from 'components/RegisterForm/RegisterForm';
import Title from 'components/Title/Title';

const Register: React.FC = () => {
  const ionRouter = useIonRouter();
  const { conferenceId, activityId } = useParams<any>();
  const setUser = useSetAtom(userAtom);
  const redirectUrl = useAtomValue(redirectAtom);

  const [present] = useIonAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const redirectUser = () => {
    if (activityId) {
      ionRouter.push(
        getRouteUrl(conferenceId, activityId, ''),
        'forward',
        'replace',
      );
    } else {
      ionRouter.push(`/${redirectUrl}`, 'forward', 'replace');
    }
  };

  const onSubmit = async (data: any) => {
    const user: IUser = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      your_role: data.your_role,
      state_cd: data.state,
      clinic_crm_id: data.clinic.crm_id || '',
      clinic_other_name: data.ownClinic ? data.ownClinic : '',
      clinic_other_zip: data.clinic.zip || data.zip,
      address_line_1: data.addressLine,
      address_city: data.city,
    };

    setIsLoading(true);
    const res = await postUser(user);
    setIsLoading(false);

    if (res && res.ok) {
      present({
        cssClass: 'elanco--alert',
        header: 'Thank you!',
        message: 'You’ve been registered. Start learning with Elanco.',
        buttons: [
          {
            text: 'Continue',
            handler: (d) => {
              setUser(res.data);
              redirectUser();
            },
          },
        ],
        onDidDismiss: (e) => {
          setUser(res.data);
          redirectUser();
        },
      });
    } else if (res) {
      present({
        cssClass: 'elanco--alert',
        header: 'Email already registered',
        message:
          'This email address is already registered. Please try signing in instead.',
        buttons: [
          {
            text: 'Close',
            cssClass: 'button-outline',
          },
          {
            text: 'Sign in',
            cssClass: '',
            handler: (d) => {
              ionRouter.push(
                getRouteUrl(conferenceId, activityId, '/sign-in'),
                'forward',
                'replace',
              );
            },
          },
        ],
      });
    }
  };

  return (
    <Layout menuButton={false}>
      <div className="Register-page">
        <IonLoading message="Please Wait..." isOpen={isLoading} />

        <Title text="Sign up" />

        <IonText color="white">
          <p>
            Please register to access the Elanco Hub and participate in our
            learning game.
          </p>

          <p>
            Already have an account? <br /> Sign in by clicking{' '}
            <Link
              replace
              to={getRouteUrl(conferenceId, activityId, '/sign-in')}>
              here
            </Link>
            .
          </p>

          <p></p>

          <p>
            Required fields are indicated with an{' '}
            <IonText color="danger">*</IonText>.
          </p>
        </IonText>

        <RegisterForm onSubmit={onSubmit} />
      </div>
    </Layout>
  );
};

export default Register;

import { IonReactRouter } from '@ionic/react-router';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import TagManager from 'react-gtm-module';

// pages
import Menu from 'components/Menu/Menu';
import AppRoutes from 'components/Navigation/AppRoutes';

/* Theme variables */
import './theme/global.scss';
import './theme/variables.scss';
import { SessionAtom } from 'atoms';

if (process.env.REACT_APP_GTM) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM,
  };
  TagManager.initialize(tagManagerArgs);
}

setupIonicReact({
  mode: 'md',
});

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <Menu />
      <SessionAtom />
      <IonRouterOutlet id="main-content">
        <AppRoutes />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;

import { useState } from 'react';
import { IonButton, IonSelect, IonSelectOption } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { useSetAtom } from 'jotai';
import { rolesData } from 'global/roles';
import { IUser } from 'models';
import { updateUser } from 'global';
import { userAtom } from 'atoms';
import EditHeader from '../EditHeader';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import PlaceholderContactForm from './Placeholder';
import { toast } from 'global/toast';

interface UserProps {
  user: IUser;
}

const ContactForm = ({ user }: UserProps) => {
  const setUser = useSetAtom(userAtom);
  const [isLoaded, setIsLoaded] = useState(false);
  const your_role =
    rolesData.find((role) => role.value === user.your_role)?.value || 'other';
  const your_role_label =
    rolesData.find((role) => role.value === user.your_role)?.name || 'Other';
  const [editMode, setEditMode] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      your_role: your_role,
      email: user.email,
    },
  });

  const onSubmit = async (data: any) => {
    if (user.user_id) {
      setIsLoaded(true);
      const res = await updateUser(data);
      if (res?.ok) {
        setUser(res.data);
        setEditMode(false);
        setIsLoaded(false);
      } else {
        setIsLoaded(false);
        setEditMode(false);
        toast('Error updating user', 'danger');
      }
    }
  };

  const onToggleEdit = () => {
    setEditMode(!editMode);
  };

  const fullname = user.first_name + ' ' + user.last_name;

  return (
    <div className="elanco--form-myprofile">
      <EditHeader
        title={'Contact Information'}
        isFormValid={isValid}
        editMode={editMode}
        onToggle={onToggleEdit}
      />

      {isLoaded && <PlaceholderContactForm />}

      {!isLoaded && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* First Name */}
          <div className="elanco--item">
            {editMode && <label>First Name</label>}
            {!editMode && <label id="first_name">Name</label>}

            {!editMode && (
              <div className="form-myprofile--label">{fullname}</div>
            )}
            {editMode && (
              <>
                <input
                  type="text"
                  {...register('first_name', {
                    value: user.first_name,
                    required: true,
                  })}
                />
                {errors.first_name && <ErrorMessage />}
              </>
            )}
          </div>
          {/* Last Name */}
          <div className="elanco--item">
            {editMode && <label>Last Name</label>}
            {editMode && (
              <>
                <input
                  type="text"
                  {...register('last_name', {
                    value: user.last_name,
                    required: true,
                  })}
                />
                {errors.last_name && <ErrorMessage />}
              </>
            )}
          </div>

          {/* Email */}
          <div className="elanco--item">
            <label>Email</label>
            {!editMode && (
              <div className="form-myprofile--label">{user.email}</div>
            )}
            {editMode && (
              <>
                <input
                  type="email"
                  {...register('email', {
                    required: true,
                    value: user.email,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                />
                {errors.email && <ErrorMessage />}
              </>
            )}
          </div>

          {/* Your Role */}
          <div className="elanco--item">
            {(your_role || editMode) && <label>Your Role</label>}
            {!editMode && (
              <div className="form-myprofile--label">{your_role_label}</div>
            )}
            {editMode && (
              <>
                <IonSelect
                  interface="popover"
                  value={your_role}
                  placeholder=""
                  {...register('your_role', {
                    required: true,
                    value: user.your_role,
                  })}>
                  {rolesData.map((option: any, i: number) => (
                    <IonSelectOption key={i} value={option.value}>
                      {option.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>

                {errors.your_role && <ErrorMessage />}
              </>
            )}
          </div>

          {editMode && (
            <div className="form-contact--footer">
              <IonButton
                type="submit"
                disabled={!isValid}
                onClick={onToggleEdit}
                strong>
                Save
              </IonButton>
              <IonButton onClick={onToggleEdit} strong fill="outline">
                Cancel
              </IonButton>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default ContactForm;

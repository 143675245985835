import { IonSkeletonText } from '@ionic/react';

const PlaceholderAddPrefferedShipping: React.FC = () => (
  <>
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px', marginBottom: '20px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px', marginBottom: '20px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px', marginBottom: '20px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px', marginBottom: '20px' }}
    />
    <IonSkeletonText animated style={{ width: '100%', height: '70px' }} />
  </>
);

export default PlaceholderAddPrefferedShipping;

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getRouteUrl = (
  conferenceId: string,
  activityId: string,
  page: string,
) => {
  const pathConference = `/conference/${conferenceId}`;
  const pathActivity = `/activity/${activityId}`;

  if (page && conferenceId && activityId) {
    return page + pathConference + pathActivity;
  } else if (conferenceId && activityId) {
    return pathConference + pathActivity;
  }

  if (conferenceId && page && !activityId) {
    return page + pathConference;
  }

  return page;
};

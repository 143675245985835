import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAtomValue, useSetAtom } from 'jotai';
import { isAuthenticatedAtom, redirectAtom } from 'atoms';

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const setRedirect = useSetAtom(redirectAtom);

  return (
    <Route
      {...rest}
      render={function (props: any) {
        if (isAuthenticated) {
          if (props.match?.path === '/') {
            return <Redirect to="dashboard" />;
          }
          return <Component {...props} />;
        } else if (props.match.params?.conferenceId) {
          if (props.match?.path.includes('schedule')) {
            setRedirect('schedule');
          }
          const url = `/welcome${props.match.url}`;
          return <Redirect to={url} />;
        } else {
          return <Redirect to="/not-found" />;
        }
      }}
    />
  );
};

export default PrivateRoute;

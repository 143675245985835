import { useAtomValue, useSetAtom } from 'jotai';
import { useParams } from 'react-router-dom';
import { IonSpinner } from '@ionic/react';
import { IPoints } from 'models';
import { getUser, postUserPoints, postUserLastActivity } from 'global';
import { useUserActivity } from 'hooks';
import { userAtom, userEmailAtom, userIdAtom } from 'atoms';

import Layout from 'components/Layout/Layout';
import GameScore from 'components/GameScore/GameScore';
import Quiz from 'components/Quiz/Quiz';
import QuizSet from 'components/QuizSet/QuizSet';
import PointsEarned from 'components/PointsEarned/PointsEarned';
import Completed from 'components/Completed/Completed';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const Activity: React.FC = () => {
  const setUser = useSetAtom(userAtom);
  const userId = useAtomValue(userIdAtom);
  const userEmail = useAtomValue(userEmailAtom);

  const { conferenceId, activityId } = useParams<any>();

  const [{ data, isLoading, isError }] = useUserActivity(activityId);

  // if (isError) return null;

  const hasUserCompleted = data?.isCompleted;

  const hasQuestion =
    data?.hasQuiz && data?.quiz?.question?.length ? true : false;

  const hasQuizSet = data?.quizSet && data?.quizSet.length ? true : false;

  const updateUser = async (answer: boolean) => {
    // only if asnwer is correct
    if (!answer) return;

    const points: IPoints = {
      userId: Number(userId),
      conferenceId: conferenceId,
      activityId: activityId,
      points: Number(data?.pointValue),
    };

    const pointsRes = await postUserPoints(points);

    if (pointsRes?.ok) {
      const resUser = await getUser(userEmail);
      if (resUser?.ok) {
        setUser(resUser.data);
      }
    }
  };

  const updateUserLastActivity = async (answer: boolean, lastStep: string) => {
    // only if step has been updated
    if (!lastStep || !answer) return;

    const lastActivity: any = {
      userId: Number(userId),
      conferenceId: conferenceId,
      activityId: activityId,
      lastActivity: lastStep,
    };

    const lastStepRes = await postUserLastActivity(lastActivity);

    if (lastStepRes?.ok) {
      const resUser = await getUser(userEmail);
      if (resUser?.ok) {
        setUser(resUser.data);
      }
    }
  };

  if (isError) return null;

  const footer = documentToHtmlString(data?.activityFooter);

  const views = [
    {
      condition: isLoading,
      component: (
        <IonSpinner
          name="dots"
          style={{ margin: '0 auto', display: 'block' }}
          color="white"
        />
      ),
    },
    {
      condition: hasUserCompleted,
      component: data && <Completed title={data.title} />,
    },
    {
      condition: !hasUserCompleted && !hasQuestion && !hasQuizSet,
      component: data && (
        <PointsEarned
          onComplete={updateUser}
          points={data.pointValue}
          title={data.title}
        />
      ),
    },
    {
      condition: !hasUserCompleted && hasQuizSet,
      component: data && (
        <QuizSet
          onComplete={updateUser}
          onProgress={updateUserLastActivity}
          activity={data}
        />
      ),
    },
    {
      condition: !hasUserCompleted && hasQuestion,
      component: data && <Quiz onComplete={updateUser} activity={data} />,
    },
  ];

  // loop over the objects above and outputs the component of the first condition that returns true
  const renderView = () => {
    const view = views.find((view) => view.condition === true);
    return view?.component;
  };

  return (
    <Layout copyright={footer}>
      <div className="Quiz-page">
        <GameScore />
        {renderView()}
      </div>
    </Layout>
  );
};

export default Activity;

// import { toast } from 'global/toast';
import { getFetchHeaders } from './fetcher';

export const getRequest = async (url: string) => {
  const headers = getFetchHeaders();

  try {
    const res = await fetch(url, { headers });

    if (!res.ok) {
      const json = await res.json();
      throw new Error(json.error);
    }

    if (res.ok) {
      const data = await res.json();
      return {
        ok: true,
        data,
      };
    }
  } catch (e: any) {
    console.log(e.message, 'danger');
    return {
      ok: false,
      error: e.message,
    };
  }
};

export const sendRequest = async (
  url: string,
  data: unknown,
  method = 'POST',
) => {
  const headers = getFetchHeaders();

  try {
    const res = await fetch(url, {
      method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      const json = await res.json();
      throw new Error(json.error);
    }

    if (res.ok) {
      const data = await res.json();
      return {
        ok: true,
        data,
      };
    }
  } catch (e: any) {
    console.log(e.message, 'danger');
    return {
      ok: false,
      error: e.message,
    };
  }
};

import useSWR from 'swr';
import { CONFIG_API_URL } from 'global/config';
import { IContenfulConference } from 'models';

export function useConference() {
  const url = `${CONFIG_API_URL}/contentful/conference`;
  const { data, error } = useSWR<IContenfulConference>(url);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

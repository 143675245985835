import {
  IonIcon,
  IonItem,
  IonLabel,
} from '@ionic/react';

import './ProgressListItem.scss';

import { 
  ellipseOutline,
  checkmarkCircle
} from 'ionicons/icons';
  
interface ProgressListItemProps {
  title: string,
  value: number,
  isComplete: boolean,
}

const ProgressListItem: React.FC<ProgressListItemProps> = ({title, value, isComplete}) => {
  return ( 
    <IonItem color="ion-white" lines="none" className={`elanco-progress-item ${isComplete ? 'elanco-progress-complete' : ''}`}>
      <IonIcon size="small" className="ml-1 mr-3" color="success" icon={isComplete ? checkmarkCircle : ellipseOutline} />
      <IonLabel className="elanco-progress-title">{title}</IonLabel>
      {
        value && 
        <IonLabel className="elanco-progress-value">
          <strong>{value}</strong>
        </IonLabel>
      }
    </IonItem>
  )
};

export default ProgressListItem;

import { IonText } from '@ionic/react';
import { useParams } from 'react-router-dom';
import { uuid } from 'global';
import { IProducts } from 'models';
import { useProducts } from 'hooks/useProducts';
import Layout from 'components/Layout/Layout';
import GameScore from 'components/GameScore/GameScore';
import PlaceholderCardBox from 'components/CardBox/Placeholder';
import CardBox from 'components/CardBox/CardBox';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useAtomValue } from 'jotai';
import { isAuthenticatedAtom } from 'atoms';

const ProductDetail: React.FC = () => {
  const { productDetailerId } = useParams<any>();
  const { data, isLoading, isError } = useProducts(productDetailerId);
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);

  if (isError) return null;

  const footer = documentToHtmlString(data?.footer);

  return (
    <Layout
      backButton={isAuthenticated}
      menuButton={isAuthenticated}
      copyright={footer}>
      <div className="ProductDetail-page">
        {isAuthenticated && <GameScore showLearn />}

        <IonText color="white">
          <h1 className="ion-text-center">Product detailers</h1>
        </IonText>

        <div className="pb-8" />

        {isLoading || !data ? (
          <PlaceholderCardBox />
        ) : (
          data.products?.map((item: IProducts) => (
            <CardBox key={uuid()} item={item} />
          ))
        )}
      </div>
    </Layout>
  );
};

export default ProductDetail;

import './MyProfileForm.scss';
import { useAtomValue } from 'jotai';
import { userAtom } from 'atoms';
import ContactForm from './ContactForm/ContactForm';
import ShippingAddressForm from './ShippingAddressForm/ShippingAddressForm';
import ClinicForm from './ClinicForm/ClinicForm';

const MyProfileForm: React.FC = () => {
  const user = useAtomValue(userAtom);

  return (
    <>
      {user && <ContactForm user={user} />}
      {user && <ClinicForm user={user} />}
      <ShippingAddressForm address={user?.shipping_address} />
    </>
  );
};

export default MyProfileForm;

import { IonSkeletonText } from '@ionic/react';

export const PlaceholderFreeformHeadline: React.FC<{ className?: string }> = ({
  className,
}) => (
  <>
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '50px' }}
      className={className}
    />
  </>
);

const PlaceholderFreeform: React.FC<{ className?: string }> = ({
  className,
}) => (
  <>
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '90px' }}
      className={className}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '40px' }}
      className={className}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px' }}
      className={className}
    />
  </>
);

export default PlaceholderFreeform;

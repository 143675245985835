import './VisitMyElanco.scss';
import { IonButton, IonImg, IonText } from '@ionic/react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useFooter } from 'hooks';
import Layout from 'components/Layout/Layout';
import Title from 'components/Title/Title';

const VisitMyElanco: React.FC = () => {
  const { data: dataFooter } = useFooter();
  const footer = documentToHtmlString(dataFooter?.footer);

  return (
    <Layout copyright={footer}>
      <div className="VisitMyElanco-page">
        <Title text="Continue your learning with Elanco" />

        <IonText color="white">
          <p>
            Easily access all the information you need to treat and protect your
            patients along with practice management resources to help optimize
            your business. All in one convenient location.
          </p>
        </IonText>

        <IonImg
          src="/assets/visit-my-elanco.jpg"
          alt="Continue your learning with Elanco"
        />

        <div className="ion-text-center pt-7">
          <a target="_blank" href="http://myelanco.com/" rel="noreferrer">
            <IonButton strong>Visit myElanco.com</IonButton>
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default VisitMyElanco;

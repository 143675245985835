import useSWR from 'swr';
import { CONFIG_API_URL } from 'global/config';
import { IUser } from 'models';

export function useUserLogin(email?: string) {
  const url = email
    ? `${CONFIG_API_URL}/user/login?email=${encodeURIComponent(email)}`
    : null;
  const { data, error } = useSWR<IUser, any>(url);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

import { IonText, IonList, useIonRouter } from '@ionic/react';
import { uuid } from 'global';
import { IProductTypes } from 'models';
import { useProductCategories } from 'hooks/useProductCategories';
import GameScore from 'components/GameScore/GameScore';
import Layout from 'components/Layout/Layout';
import CardButton from 'components/CardButton/CardButton';
import PlaceholderCardBox from 'components/CardBox/Placeholder';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const Products: React.FC = () => {
  const ionRouter = useIonRouter();
  const { data, isLoading, isError } = useProductCategories();

  if (isError) return null;

  const footer = documentToHtmlString(data?.footer);

  return (
    <Layout copyright={footer}>
      <div className="Products-page">
        <GameScore showLearn />

        <IonText color="white">
          <h1 className="ion-text-center">Product detailers</h1>
        </IonText>

        <div className="pb-8" />

        <IonList>
          {isLoading || !data ? (
            <PlaceholderCardBox />
          ) : (
            data.productType.map((item: IProductTypes) => (
              <CardButton
                isProduct={true}
                key={uuid()}
                item={item}
                onClick={() => ionRouter.push(`/products/${item.id}`)}
              />
            ))
          )}
        </IonList>
      </div>
    </Layout>
  );
};

export default Products;

import { useState, SetStateAction, useEffect } from 'react';
import { IonButton, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import { useForm } from 'react-hook-form';
import { statesData } from 'global/states';
import { IOtherClinic } from 'models';
import { updateUser } from 'global';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import PlaceholderCustomClinicForm from './Placeholder';

interface CustomClinicProps {
  clinic: IOtherClinic;
  onSaveForm: (data: IOtherClinic) => void;
  onCancel: () => void;
  setEditMode: React.Dispatch<SetStateAction<boolean>>;
}

const CustomClinicForm = ({
  clinic,
  onSaveForm,
  onCancel,
  setEditMode,
}: CustomClinicProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const state_cd = statesData.find(
    (state) => state.value === clinic.state_cd,
  )?.value;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      clinic_crm_id: null,
      state_cd: state_cd,
      clinic_other_name: clinic.clinic_other_name,
      clinic_other_zip: clinic.clinic_other_zip,
      address_city: clinic.address_city,
      address_line_1: clinic.address_line_1,
    },
  });

  useEffect(() => {
    if (clinic.clinic_other_name === 'Add My Own Clinic') {
      setValue('clinic_other_name', '');
    } else {
      setValue('clinic_other_name', clinic.clinic_other_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinic.clinic_other_name]);

  const onSubmit = async (data: any) => {
    setIsLoaded(true);
    const res = await updateUser(data);
    if (res?.ok) {
      onSaveForm(data);
      setIsLoaded(false);
    }
  };

  const onToggleEdit = () => {
    setEditMode(false);
  };

  const isReadyOnly =
    clinic.clinic_other_name === 'Add My Own Clinic' ? false : true;

  return (
    <div className="elanco--form-customclinic elanco--form-myprofile">
      {isLoaded && <PlaceholderCustomClinicForm />}

      {!isLoaded && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Add My Own Clinic */}
          <div className="elanco--item">
            {isReadyOnly && <label>Clinic Name</label>}

            {!isReadyOnly && (
              <label>
                Enter Your Clinic Name <IonText color="danger">*</IonText>
              </label>
            )}

            <input
              type="text"
              readOnly={isReadyOnly}
              {...register('clinic_other_name', {
                value: clinic.clinic_other_name,
                required: true,
              })}
            />
          </div>

          {/* Address */}
          <div className="elanco--item has-description">
            <label>
              Address <IonText color="danger">*</IonText>
            </label>

            <input
              type="text"
              {...register('address_line_1', {
                value: clinic.address_line_1,
                required: true,
              })}
            />
            {errors.address_line_1 && <ErrorMessage />}
          </div>

          {/* City */}
          <div className="elanco--item">
            <label>
              City <IonText color="danger">*</IonText>
            </label>

            <input
              type="text"
              {...register('address_city', {
                value: clinic.address_city,
                required: true,
              })}
            />
            {errors.address_city && <ErrorMessage />}
          </div>

          {/* State */}
          <div className="elanco--item has-description">
            <label>
              State <IonText color="danger">*</IonText>
            </label>

            <IonSelect
              interface="popover"
              placeholder=""
              value={state_cd}
              {...register('state_cd', { required: true })}>
              {statesData.map((option: any, i: number) => (
                <IonSelectOption key={i} value={option.value}>
                  {option.name}
                </IonSelectOption>
              ))}
            </IonSelect>

            {errors.state_cd && <ErrorMessage />}
          </div>

          {/* Zip */}
          <div className="elanco--item">
            <label>
              Clinic Zip <IonText color="danger">*</IonText>
            </label>

            <input
              type="text"
              maxLength={5}
              {...register('clinic_other_zip', {
                value: clinic.clinic_other_zip,
                maxLength: 5,
                required: true,
              })}
            />
            {errors.clinic_other_zip && <ErrorMessage />}
            {/* {errors.clinic_other_zip?.type === 'maxLength' && (
              <ErrorMessage text="Max length 5 digits" />
            )} */}
          </div>

          <div className="form-contact--footer">
            <IonButton
              type="submit"
              disabled={!isValid}
              onClick={onToggleEdit}
              strong>
              Save
            </IonButton>
            <IonButton onClick={onCancel} strong fill="outline">
              Cancel
            </IonButton>
          </div>
        </form>
      )}
    </div>
  );
};

export default CustomClinicForm;

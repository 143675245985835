import useSWR from 'swr';
import { CONFIG_API_URL } from 'global/config';
import { IFreeforms } from 'models';

export function useFreeforms() {
  let url = `${CONFIG_API_URL}/contentful/freeforms`;
  const { data, error } = useSWR<IFreeforms>(url);

  console.log(data);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

export const IconLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="42"
    fill="none"
    viewBox="0 0 85 42">
    <path
      fill="#fff"
      d="M25.87 22.587c-.79.915-1.262 3.068-1.262 4.433 0 .747.216 1.063.73 1.063.445 0 1.221-.767 1.303-1.13l1.026-4.816a.756.756 0 00-.452-.148c-.277 0-.81-.02-1.351.598h.007zM56.367 21.82c-1.54 0-2.18 4.602-2.18 5.368 0 .68.235 1.003.83 1.003 1.54 0 2.18-4.622 2.18-5.368 0-.747-.276-1.002-.837-1.002h.007z"></path>
    <path
      fill="#fff"
      d="M0 14.859L4.882 42 85 27.881V0L0 14.859zM14.202 30.51H5.26L8.428 15.66h8.664l-.939 3.135h-4.558l-.534 2.495h3.721l-.641 3.027H10.42l-.642 3.027h5.112l-.682 3.175-.006-.007zm3.957.276c-3.147 0-2.87-1.621-2.397-3.814l1.607-7.5c.553-2.556.6-3.363.58-3.982l3.87-.639c.04.531-.088 1.662-.317 2.751l-1.756 8.16c-.405 1.937-.453 2.448.385 2.448.128 0 .256-.02.405-.067l.108 2.3c-.534.195-1.452.343-2.478.343h-.007zm9.59.148c-.453-.235-.77-.895-.838-1.298-.493.511-1.58 1.217-2.87 1.217-2.25 0-3.188-1.406-3.188-3.753 0-2.348.939-4.776 2.377-6.202 1.263-1.258 2.607-1.661 4.559-1.661 1.519 0 3.403.255 4.213.659-.317.767-.641 2.085-.837 3.007l-.837 3.962c-.088.363-.068 1.13.425 1.66l-3.018 2.409h.013zm9.048-.424l1.5-7.029c.195-.854.087-1.238-.534-1.238s-1.067.296-1.54 1.023l-1.56 7.244h-3.72l1.478-6.921c.345-1.662.473-2.644.297-3.727l3.296-.619c.087.364.169.747.189 1.191.857-.747 2.14-1.19 3.356-1.19 2.762 0 3.039 1.553 2.695 3.154l-1.736 8.119h-3.72v-.007zm9.157.276c-3.08 0-4.578-1.957-3.788-5.67.79-3.727 3.059-5.88 6.314-5.88 1.58 0 2.438.404 3.336 1.299l-2.181 2.24c-.318-.444-.878-.807-1.392-.807-.857 0-1.499.679-1.971 2.919-.473 2.24-.426 3.155.641 3.155.838 0 1.607-.404 2.222-.982l1.114 2.172c-1.432 1.17-2.951 1.554-4.302 1.554h.007zm8.414 0c-2.613 0-4.085-1.426-4.085-3.962 0-2.832 1.54-7.587 6.76-7.587 2.31 0 4.085 1.13 4.085 3.901s-1.607 7.648-6.76 7.648zM79.672 30.794h.864V33.1h.392v-2.307h.864v-.35h-2.12v.35zM82.758 33.1v-2.4h.02l.736 2.4h.318l.756-2.4h.014v2.4h.391v-2.656h-.702l-.594 1.977h-.014l-.635-1.977h-.682V33.1h.392z"></path>
  </svg>
);

export const IconGift = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="85"
    height="42"
    fill="none"
    viewBox="0 0 85 42">
    <path
      fill="#fff"
      d="M25.87 22.587c-.79.915-1.262 3.068-1.262 4.433 0 .747.216 1.063.73 1.063.445 0 1.221-.767 1.303-1.13l1.026-4.816a.756.756 0 00-.452-.148c-.277 0-.81-.02-1.351.598h.007zM56.367 21.82c-1.54 0-2.18 4.602-2.18 5.368 0 .68.235 1.003.83 1.003 1.54 0 2.18-4.622 2.18-5.368 0-.747-.276-1.002-.837-1.002h.007z"></path>
    <path
      fill="#fff"
      d="M0 14.859L4.882 42 85 27.881V0L0 14.859zM14.202 30.51H5.26L8.428 15.66h8.664l-.939 3.135h-4.558l-.534 2.495h3.721l-.641 3.027H10.42l-.642 3.027h5.112l-.682 3.175-.006-.007zm3.957.276c-3.147 0-2.87-1.621-2.397-3.814l1.607-7.5c.553-2.556.6-3.363.58-3.982l3.87-.639c.04.531-.088 1.662-.317 2.751l-1.756 8.16c-.405 1.937-.453 2.448.385 2.448.128 0 .256-.02.405-.067l.108 2.3c-.534.195-1.452.343-2.478.343h-.007zm9.59.148c-.453-.235-.77-.895-.838-1.298-.493.511-1.58 1.217-2.87 1.217-2.25 0-3.188-1.406-3.188-3.753 0-2.348.939-4.776 2.377-6.202 1.263-1.258 2.607-1.661 4.559-1.661 1.519 0 3.403.255 4.213.659-.317.767-.641 2.085-.837 3.007l-.837 3.962c-.088.363-.068 1.13.425 1.66l-3.018 2.409h.013zm9.048-.424l1.5-7.029c.195-.854.087-1.238-.534-1.238s-1.067.296-1.54 1.023l-1.56 7.244h-3.72l1.478-6.921c.345-1.662.473-2.644.297-3.727l3.296-.619c.087.364.169.747.189 1.191.857-.747 2.14-1.19 3.356-1.19 2.762 0 3.039 1.553 2.695 3.154l-1.736 8.119h-3.72v-.007zm9.157.276c-3.08 0-4.578-1.957-3.788-5.67.79-3.727 3.059-5.88 6.314-5.88 1.58 0 2.438.404 3.336 1.299l-2.181 2.24c-.318-.444-.878-.807-1.392-.807-.857 0-1.499.679-1.971 2.919-.473 2.24-.426 3.155.641 3.155.838 0 1.607-.404 2.222-.982l1.114 2.172c-1.432 1.17-2.951 1.554-4.302 1.554h.007zm8.414 0c-2.613 0-4.085-1.426-4.085-3.962 0-2.832 1.54-7.587 6.76-7.587 2.31 0 4.085 1.13 4.085 3.901s-1.607 7.648-6.76 7.648zM79.672 30.794h.864V33.1h.392v-2.307h.864v-.35h-2.12v.35zM82.758 33.1v-2.4h.02l.736 2.4h.318l.756-2.4h.014v2.4h.391v-2.656h-.702l-.594 1.977h-.014l-.635-1.977h-.682V33.1h.392z"></path>
  </svg>
);

export const IconArrowRightSmall = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="10"
    fill="none"
    viewBox="0 0 20 10">
    <path
      stroke="#0072CE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M19 5H1m14-4l4 4-4-4zm4 4l-4 4 4-4z"></path>
  </svg>
);

export const IconBookmark = () => (
  <svg
    width="16"
    height="21"
    viewBox="0 0 16 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3C9.65685 3 11 1.65685 11 0H16V12H0V0H5C5 1.65685 6.34315 3 8 3Z"
      fill="#0072CE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 18C6.34315 18 5 19.3431 5 21L0 21L1.04907e-06 9L16 9L16 21L11 21C11 19.3431 9.65685 18 8 18Z"
      fill="#0072CE"
    />
    <path
      d="M8 6L8.89806 8.76393H11.8042L9.45308 10.4721L10.3511 13.2361L8 11.5279L5.64886 13.2361L6.54692 10.4721L4.19577 8.76393H7.10194L8 6Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3C9.65685 3 11 1.65685 11 0H16V12H0V0H5C5 1.65685 6.34315 3 8 3Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 18C6.34315 18 5 19.3431 5 21L-1.90735e-06 21L-8.58275e-07 9L16 9L16 21L11 21C11 19.3431 9.65685 18 8 18Z"
      fill="currentColor"
    />
    <path
      d="M8 6L8.89806 8.76393H11.8042L9.45308 10.4721L10.3511 13.2361L8 11.5279L5.64886 13.2361L6.54692 10.4721L4.19577 8.76393H7.10194L8 6Z"
      fill="white"
    />
  </svg>
);

export const IconCarrot = () => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7251 7.06494L6.8625 0.999817L0.999909 7.06494"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconCruz = ({ w = '15', h = '15' }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.08203 5.82715H14.751V9.03516H9.08203V14.6748H5.87402V9.03516H0.205078V5.82715H5.87402V0.114258H9.08203V5.82715Z"
      fill="currentColor"
    />
  </svg>
);

export const IconArrow = () => (
  <svg
    width="6"
    height="11"
    viewBox="0 0 6 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.304675 10.6783C0.117581 10.4723 0.0124768 10.1929 0.0124768 9.90156C0.0124769 9.61023 0.117581 9.33083 0.304676 9.1248L3.59106 5.50687L0.304676 1.88894C0.209358 1.78759 0.133329 1.66635 0.081025 1.53231C0.0287213 1.39827 0.00119046 1.2541 3.89784e-05 1.10822C-0.0011125 0.962339 0.0241382 0.817667 0.0743181 0.682644C0.124498 0.547621 0.198602 0.424953 0.292306 0.321795C0.38601 0.218638 0.497438 0.137057 0.620088 0.0818158C0.742737 0.0265733 0.874152 -0.00122631 1.00667 4.20937e-05C1.13918 0.00130954 1.27013 0.0316173 1.39189 0.0891983C1.51365 0.146778 1.62377 0.230478 1.71584 0.335413L5.7078 4.73011C5.8949 4.93614 6 5.21554 6 5.50687C6 5.7982 5.8949 6.0776 5.7078 6.28363L1.71584 10.6783C1.52868 10.8843 1.27489 11 1.01026 11C0.745624 11 0.491826 10.8843 0.304675 10.6783Z"
      fill="currentColor"
    />
  </svg>
);

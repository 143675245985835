import './NotConferenceId.scss';
import { IonContent, IonPage, IonText, IonButton } from '@ionic/react';
import { IconLogo } from 'icons';

const NotConferenceId: React.FC = () => {
  return (
    <IonPage className="NotConferenceId-page">
      <IonContent className="ion-padding">
        <div className="elanco--logo">
          <IconLogo />
        </div>

        <IonText color="white">
          <h1 className="ion-text-center">Oops!</h1>
          <p className="ion-text-center">
            Please click below to reset your app and try again, if the issue
            persist please find a representive to help get you registered for
            this event.
          </p>
        </IonText>

        <IonButton
          routerLink={'/welcome/conference/amsQshzSrWO66PHMFvLAF'}
          strong
          style={{ display: 'flex' }}>
          Return Home
        </IonButton>

        {/* <Copyright /> */}
      </IonContent>
    </IonPage>
  );
};

export default NotConferenceId;

import './Progress.scss';
import { IonAccordionGroup } from '@ionic/react';
import Title from 'components/Title/Title';
import Layout from 'components/Layout/Layout';
import GameScore from 'components/GameScore/GameScore';
import AccordionItem from 'components/AccordionItem/AccordionItem';
import ProgressListItem from 'components/ProgressListItem/ProgressListItem';
import {useMyProgress} from "../../hooks/useMyProgress";
import PlaceholderCardBox from "../../components/CardBox/Placeholder";
import {IActivityCategory, ICategoryActivity} from "../../models";
import {useFooter} from "../../hooks";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";



const Progress: React.FC = () => {
    const { data, isLoading, isError } = useMyProgress();
    const { data: dataFooter } = useFooter('myProgressFooter');

    if (isError) return null;

    const registrationPoints = data?.registrationPoints as number;
    const footer = documentToHtmlString(dataFooter?.footer);
  return (
    <Layout copyright={footer}>
      <div className="Progress-page">
        <GameScore />

        <Title text="My progress" />

        <IonAccordionGroup className="mt-8">
          { isLoading || !data? (
              <PlaceholderCardBox />
          ): (
              data.activities?.map((item: IActivityCategory, i) => (
                  <AccordionItem
                      key={i}
                      title={item.title}
                      progress={{ current: item.userCategoryPoints, total: item.categoryPoints }}>
                    {item.categoryActivities?.map((item: ICategoryActivity, i: number) => (
                        <ProgressListItem key={i} title={item.title} value={item.activityPoints} isComplete={item.isCompleted}/>
                    ))}
                  </AccordionItem>
              ))
          )}
            <AccordionItem
                title="Registration"
                progress={{ current: registrationPoints, total: registrationPoints }}>
              <ProgressListItem title={"Registration"} value={registrationPoints} isComplete={true}/>
            </AccordionItem>
        </IonAccordionGroup>
      </div>
    </Layout>
  );
};

export default Progress;

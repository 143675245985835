export const getFetchHeaders = () => {
  let headers: HeadersInit = {};

  try {
    let elanco_conference_id = JSON.parse(
      localStorage.getItem('elanco_conference_id') || '',
    );

    const url = new URL(window.location.href);
    const paths = url.pathname.split('/');

    // only if user created an account for X show but same
    // browser use a qrcode for another show
    if (paths[1] === 'conference') {
      elanco_conference_id = paths[2];
    }

    const elanco_user_id = JSON.parse(
      // @ts-ignore
      localStorage.getItem('elanco_user') || null,
    )?.user_id;

    if (elanco_conference_id !== '') {
      headers.elanco_conference_id = elanco_conference_id;
    }

    if (elanco_user_id) {
      headers.elanco_user_id = elanco_user_id;
    }
  } catch {
    console.log('fetch error');
  }

  return headers;
};

const fetcher = async (url: string) => {
  try {
    const res = await fetch(url, { headers: getFetchHeaders() });

    if (!res.ok) {
      // toast('An error occurred while fetching the data.', 'danger');
      throw new Error('An error occurred while fetching the data.');
    }

    return res.json();
  } catch (error) {
    // console.log(error);
    // toast('An error occurred while fetching the data.', 'danger');
    throw new Error('An error occurred while fetching the data.');
  }
};

export default fetcher;

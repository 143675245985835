import { IonAccordion, IonItem, IonLabel } from '@ionic/react';

import './AccordionItem.scss';

import { chevronDownCircle } from 'ionicons/icons';

interface AccordionItemProps {
  title: string;
  id?: string;
  progress?: {
    current: number;
    total: any;
  };
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  id,
  progress,
  children,
}) => {
  const progressPercent = progress && (progress.current / progress.total) * 100;
  return (
    <IonAccordion
      className="elanco-accordion-item mb-3"
      value={id || title}
      toggleIcon={chevronDownCircle}
      toggleIconSlot="start">
      <IonItem
        className="elanco-accordion-header"
        slot="header"
        style={
          progress && {
            '--background': `linear-gradient(90deg, rgba(203,236,255,1) 0%, rgba(203,236,255,1) ${progressPercent}%, rgba(255,255,255,1) ${progressPercent}%)`,
          }
        }>
        <IonLabel className="elanco-accordion-title">{title}</IonLabel>
        {progress && (
          <IonLabel className="elanco-accordion-progress">
            {progress.current} | <strong>{progress.total}</strong>
          </IonLabel>
        )}
      </IonItem>
      <IonItem className="elanco-accordion-content" slot="content">
        <div className="elanco-accordion-content-wrapper">{children}</div>
      </IonItem>
    </IonAccordion>
  );
};

export default AccordionItem;

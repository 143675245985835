import './CardButton.scss';
import { IonText, IonIcon, IonItem, IonSkeletonText } from '@ionic/react';
import { IProductTypes } from 'models';
import { IconArrowRightSmall } from 'icons';

interface CardButtonProps {
  icon?: string;
  color?: string;
  title?: string;
  description?: string;
  isProduct?: boolean;
  onClick?: () => void;
  item?: IProductTypes;
  isLoading?: boolean;
}

const CardButton: React.FC<CardButtonProps> = ({
  color,
  title,
  description,
  isProduct = false,
  icon,
  onClick,
  item,
  isLoading,
}) => {
  return (
    <IonItem
      className={`elanco-cardbutton mb-3 ${isProduct ? 'is-product' : null} `}
      color={color || 'white'}
      button
      onClick={onClick}>
      {icon && <IonIcon slot="start" size="large" src={icon} />}
      <IonText color="secondary" className="elanco-cardbutton__content">
        {isLoading ? (
          <div className="elanco-cardbutton__placeholder">
            <IonSkeletonText
              style={{ width: '100%', height: '27px' }}
              animated
            />
            <IonSkeletonText
              style={{ width: '80%', height: '16px' }}
              animated
            />
          </div>
        ) : (
          <>
            <h2 className="elanco-cardbutton__title">
              {item?.title ?? title}
            </h2>
            {description && (
              <p className="elanco-cardbutton__description text-small">
                {description}
              </p>
            )}
          </>
        )}
        {onClick && <IconArrowRightSmall />}
      </IonText>
    </IonItem>
  );
};

export default CardButton;

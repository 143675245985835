import { IonText } from '@ionic/react';
// import './Title.scss';

interface TitleProps {
  color?: string;
  text: string;
  Tag?: any;
}

const Title: React.FC<TitleProps> = ({ color = 'white', text, Tag = 'h1' }) => (
  <IonText color={color}>
    <Tag className="ion-text-center text-title">{text}</Tag>
  </IonText>
);

export default Title;

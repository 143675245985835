import { IonSkeletonText } from '@ionic/react';

const PlaceholderCardPrize: React.FC = () => (
  <>
    <IonSkeletonText animated style={{ width: '100%', height: '100px' }} />
    <IonSkeletonText animated style={{ width: '100%', height: '100px' }} />
    <IonSkeletonText animated style={{ width: '100%', height: '100px' }} />
    <IonSkeletonText animated style={{ width: '100%', height: '100px' }} />
    <IonSkeletonText animated style={{ width: '100%', height: '100px' }} />
  </>
);

export default PlaceholderCardPrize;

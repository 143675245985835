import {
  IonMenu,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonSkeletonText,
  useIonRouter,
} from '@ionic/react';
import { userAtom, conferenceIdAtom } from 'atoms';
import { RESET } from 'jotai/utils';
import { useSetAtom, useAtomValue } from 'jotai';
import { usePriceVsDonations } from 'hooks/usePriceVsDonations';
import iconEvents from 'icons/icon-events.svg';
import iconGame from 'icons/icon-game.svg';
import iconProducts from 'icons/icon-products.svg';
import iconPlaceOrder from 'icons/icon-place-order.svg';
import iconVisitMyElanco from 'icons/icon-visit-my-elanco.svg';

interface MenuProps {
  color?: string;
}

// icons

const Menu: React.FC<MenuProps> = () => {
  const ionRouter = useIonRouter();
  const priceVsDonation = usePriceVsDonations();

  const setUser = useSetAtom(userAtom);
  const conferenceId = useAtomValue(conferenceIdAtom);

  const onClick = async (page: string) => {
    const ionMenu = document.querySelector('ion-menu');
    await ionMenu?.close();
    ionRouter.push(page);
  };

  const handleLogout = () => {
    setUser(RESET);
    onClick(`/conference/${conferenceId}`);
  };

  return (
    <IonMenu side="start" contentId="main-content">
      <IonContent>
        <div className="elanco--menu-container">
          <div className="elanco--menu-top">
            <IonItem
              onClick={() => {
                onClick('/learn-and-earn');
              }}>
              <IonIcon slot="start" size="large" src={iconGame} />
              {priceVsDonation.isLoading ? (
                <IonSkeletonText
                  style={{ width: '100%', height: '22px' }}
                  animated
                />
              ) : (
                <IonLabel color="secondary">
                  {`Learn & ${priceVsDonation.value ? 'Earn' : 'Give'} game`}
                </IonLabel>
              )}
            </IonItem>
            <IonItem
              onClick={() => {
                onClick('/schedule');
              }}>
              <IonIcon slot="start" size="large" src={iconEvents} />
              <IonLabel color="secondary">Event schedule</IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                onClick('/products');
              }}>
              <IonIcon slot="start" size="large" src={iconProducts} />
              <IonLabel color="secondary">Product detailers</IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                onClick('/place-an-order');
              }}>
              <IonIcon slot="start" size="large" src={iconPlaceOrder} />
              <IonLabel color="secondary">Place an order</IonLabel>
            </IonItem>
            <IonItem
              onClick={() => {
                onClick('/visit-myelanco');
              }}>
              <IonIcon slot="start" size="large" src={iconVisitMyElanco} />
              <IonLabel color="secondary">Visit myElanco.com</IonLabel>
            </IonItem>
            {/* <IonItem
              onClick={() => {
                onClick('/contact-us');
              }}>
              <IonIcon slot="start" size="large" src={iconContact} />
              <IonLabel color="secondary">Contact a rep</IonLabel>
            </IonItem> */}
          </div>
          <div className="elanco--menu-bottom">
            <IonItem
              onClick={() => {
                onClick('/manage-profile');
              }}>
              <IonIcon slot="start" size="large" color="primary" />
              <IonLabel color="secondary">Manage profile</IonLabel>
            </IonItem>
            <IonItem onClick={handleLogout}>
              <IonIcon slot="start" size="large" color="primary" />
              <IonLabel color="secondary">Logout</IonLabel>
            </IonItem>
          </div>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

import { useEffect, useState } from 'react';
import * as confetti from 'canvas-confetti';

import trophySvg from './assets/trophy.svg';
import trophyLeftConfetiSvg from './assets/left-confeti.svg';
import trophyRightConfetiSvg from './assets/right-confeti.svg';
import trophyLeftLightSvg from './assets/left-light.svg';
import trophyMiddleLightSvg from './assets/middle-light.svg';
import trophyRightLightSvg from './assets/right-light.svg';
import './Trophy.scss';

interface TrophyProps {
  color?: string;
}

let canvasRef: HTMLCanvasElement | null;
let confettiCanvas: Function;

const showConfetti = (setLight: Function) => {
  confettiCanvas({
    particleCount: 37,
    angle: 60,
    spread: 55,
    origin: { x: 0, y: 1 },
    colors: ['#0072CE', '#FFFFFF', '#0EC3A4'],
  });

  confettiCanvas({
    particleCount: 17,
    angle: 120,
    spread: 55,
    origin: { x: 1, y: 1 },
    colors: ['#0072CE', '#FFFFFF', '#0EC3A4'],
  });

  setTimeout(() => {
    setLight(true);
  }, 300);
};

const Trophy: React.FC<TrophyProps> = () => {
  const [light, setLight] = useState<boolean>(false);

  useEffect(() => {
    confettiCanvas = confetti.create(canvasRef, {
      resize: true,
      useWorker: true,
    });

    setTimeout(() => {
      showConfetti(setLight);
    });
  }, []);

  const setLightClass = () => {
    return light ? 'is-active' : null;
  };

  return (
    <div className="elanco--tropy">
      <div className={`elanco--tropy-lights ${setLightClass()}`}>
        <img alt="Trophy left light" src={trophyLeftLightSvg} />
        <img alt="Trophy middle light" src={trophyMiddleLightSvg} />
        <img alt="Trophy right light" src={trophyRightLightSvg} />

        <img alt="Trophy left confeti" src={trophyLeftConfetiSvg} />
        <img alt="Trophy right confeti" src={trophyRightConfetiSvg} />
      </div>
      <div className="ion-text-center">
        <img
          alt="Trophy"
          className="elanco--tropy-svg"
          src={trophySvg}
          onClick={() => {
            showConfetti(setLight);
          }}
        />
      </div>
      <canvas
        ref={(node) => {
          canvasRef = node;
        }}></canvas>
    </div>
  );
};

export default Trophy;

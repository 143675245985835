import { useState } from 'react';
import {
  IonButton,
  IonSelect,
  IonSelectOption,
  useIonRouter,
} from '@ionic/react';
import { useForm } from 'react-hook-form';
import { IAddress } from 'models';
import { getUser, statesData, updateUserAddress } from 'global';
import EditHeader from '../EditHeader';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import PlaceholderShippingAddressForm from './Placeholder';
import { IconCruz } from 'icons';
import { useAtom } from 'jotai';
import { userAtom } from 'atoms';
import { useDeleteAddress } from 'hooks/useDeleteAddress';

interface AddressProps {
  address?: IAddress;
}

const AddAddressButton = () => {
  const router = useIonRouter();

  return (
    <div
      onClick={() => router.push('/add-shipping-address')}
      className="elanco--item elanco--addproduct">
      <div className="elanco--icon-cruz">
        <IconCruz w="12" h="12" />
      </div>
      <label>Add Preferred shipping address</label>
    </div>
  );
};

const ShippingAddressForm = ({ address }: AddressProps) => {
  const { showDeleteAddress } = useDeleteAddress();
  const [user, setUser] = useAtom(userAtom);
  const [editMode, setEditMode] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const state = statesData.find(
    (role) => role.value === address?.state_cd,
  )?.value;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      address: address?.address,
      city: address?.city,
      state_cd: address?.state_cd,
      zip: address?.zip,
    },
  });

  if (!address) {
    return <AddAddressButton />;
  }

  const updateUser = async () => {
    const userData = await getUser(user?.email);
    userData && setUser(userData.data);
    setEditMode(false);
    setIsLoaded(false);
  };

  const onDeleteAddress = async () => {
    setIsLoaded(true);
    const isConfirmed = await showDeleteAddress();
    if (isConfirmed) {
      const res = await updateUserAddress(address, 'DELETE');
      if (res?.ok) {
        updateUser();
      }
    } else {
      setIsLoaded(false);
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoaded(true);
    const res = await updateUserAddress(data as IAddress, 'PUT');
    if (res?.ok) {
      updateUser();
    }
  };

  const onToggleEdit = () => {
    setEditMode(!editMode);
  };

  return (
    <div className="elanco--form-shipping elanco--form-myprofile">
      <EditHeader
        title={'Preferred Shipping Address'}
        isFormValid={isValid}
        showDelete={true}
        editMode={editMode}
        onToggle={onToggleEdit}
        onDelete={onDeleteAddress}
      />

      {isLoaded && <PlaceholderShippingAddressForm />}

      {!isLoaded && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {!editMode && (
            <div className="elanco--item">
              <label>Address</label>
              <p className="form-myprofile--label">
                {address.address} <br />
                {address.city}, {address.state_cd} {address.zip}
              </p>
            </div>
          )}

          {/* Address */}
          {editMode && (
            <div className="elanco--item">
              <label>Address</label>
              <input
                type="text"
                {...register('address', {
                  value: address.address,
                  required: true,
                })}
              />
              {errors.address && <ErrorMessage />}
            </div>
          )}
          {/* CIty */}
          {editMode && (
            <div className="elanco--item">
              <label>City</label>
              <input
                type="text"
                {...register('city', {
                  value: address.city,
                  required: true,
                })}
              />
              {errors.city && <ErrorMessage />}
            </div>
          )}

          {/* State */}
          {editMode && (
            <div className="elanco--item">
              <label>State</label>
              <IonSelect
                interface="popover"
                value={state}
                placeholder=""
                {...register('state_cd', {
                  required: true,
                  value: address.state_cd,
                })}>
                {statesData.map((option: any, i: number) => (
                  <IonSelectOption key={i} value={option.value}>
                    {option.name}
                  </IonSelectOption>
                ))}
              </IonSelect>

              {errors.state_cd && <ErrorMessage />}
            </div>
          )}

          {/* zip */}
          {editMode && (
            <div className="elanco--item">
              <label>zip</label>
              <input
                type="zip"
                {...register('zip', {
                  value: address.zip,
                  required: true,
                })}
              />
              {errors.zip && <ErrorMessage />}
            </div>
          )}

          {editMode && (
            <div className="form-contact--footer">
              <IonButton
                type="submit"
                disabled={!isValid}
                onClick={onToggleEdit}
                strong>
                Save
              </IonButton>
              <IonButton onClick={onToggleEdit} strong fill="outline">
                Cancel
              </IonButton>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default ShippingAddressForm;

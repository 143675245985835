import { useIonAlert } from '@ionic/react';

export function useConfirmAddress() {
  const [modal] = useIonAlert();

  const showConfirmAddress = (desc: string) => {
    return new Promise((resolve) => {
      modal({
        cssClass: ['elanco--alert-confirm'],
        header: 'Please confirm the following address is correct:',
        message: `<h4>${desc}</h4>`,
        buttons: [
          {
            text: 'Confirm',
            handler: () => resolve(true),
          },
          {
            text: 'Close',
            cssClass: 'button-outline',
            handler: () => resolve(false),
          },
        ],
        onDidDismiss: () => resolve(false),
      });
    });
  };

  return {
    showConfirmAddress,
  };
}

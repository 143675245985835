import './LearnAndEarn.scss';
import React from 'react';
import { IonText, IonButton, useIonAlert, useIonRouter } from '@ionic/react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { uuid } from 'global';
import { IPrize } from 'models';
import { useLearnAndEarn } from 'hooks/useLearnAndEarn';
import Layout from 'components/Layout/Layout';
import GameScore from 'components/GameScore/GameScore';
import Title from 'components/Title/Title';
import CardPrizeItem from 'components/CardPrize/CardPrizeItem';
import PlaceholderCardPrize from 'components/CardPrize/Placeholder';
import Freeforms from 'components/Freeforms/Freeforms';
import { useGameRules } from 'hooks';
import { usePriceVsDonations } from 'hooks/usePriceVsDonations';

interface PageContentProps {
  raffle?: {
    raffleTitle: string;
    raffleDescription: any;
    pointLevel: number;
  };
}

const PageContent: React.FC<PageContentProps> = ({ raffle }) => {
  const [modal] = useIonAlert();
  const router = useIonRouter();
  const priceVsDonation = usePriceVsDonations();

  const { showGameRulesModal, isError } = useGameRules();

  const title = priceVsDonation.isLoading
    ? 'Loading...'
    : `Learn & ${priceVsDonation.value ? 'Earn' : 'Give'} game`;

  const onClickRaffleInfo = () => {
    raffle &&
      raffle.hasOwnProperty('raffleTitle') &&
      modal({
        cssClass: 'elanco--alert',
        header: 'Win even more!',
        message: `<p>${raffle?.raffleTitle}</p> ${documentToHtmlString(
          raffle?.raffleDescription,
        )}`,
        buttons: [
          {
            text: 'Close',
            cssClass: 'button-outline',
            handler: () => console.log('close modal'),
          },
        ],
        onDidDismiss: () => console.log('did dismiss'),
      });
  };

  return (
    <>
      <GameScore />

      <Title text={title} />

      <Freeforms name="laegDescription" placeholderSize="paragraph" />

      <div className="ion-text-center" style={{ paddingBottom: 10 }}>
        <IonButton
          strong
          onClick={() => {
            router.push('/progress');
          }}>
          My Progress
        </IonButton>
      </div>

      {!isError && (
        <div className="ion-text-center" style={{ paddingBottom: 40 }}>
          <IonButton fill="outline" strong onClick={() => showGameRulesModal()}>
            <Freeforms name="laegRules" placeholderSize="spinner" />
          </IonButton>
        </div>
      )}

      <Freeforms name="laegPriceTitle" placeholderSize="spinner" />

      <Freeforms name="laegPricesDescription" placeholderSize="spinner" />

      {raffle?.pointLevel != null && (
        <IonText
          color="white"
          onClick={onClickRaffleInfo}
          className="elanco--level-modal">
          <p className="text-copy" style={{ marginBottom: 15 }}>
            <strong className="text-underline" style={{ paddingRight: 5 }}>
              Reach level {raffle?.pointLevel}
            </strong>{' '}
            for a chance to win even more prizes!
          </p>
        </IonText>
      )}
    </>
  );
};

const LearnAndEarn: React.FC = () => {
  const { data, isLoading, isError } = useLearnAndEarn();

  if (isError) return null;

  const footer = documentToHtmlString(data?.footer);

  return (
    <Layout copyright={footer}>
      <div className="LearnAndEarn-page">
        <PageContent raffle={data?.raffle} />
        {isLoading || !data ? (
          <PlaceholderCardPrize />
        ) : (
          data?.prizes.map((item: IPrize, i: number) => {
            const currentScore = data?.userPoints;
            const completed = currentScore >= item.pointsToEarn;
            const nextLevel = data?.prizes[i + 1];
            const current =
              (completed &&
                nextLevel &&
                currentScore < nextLevel?.pointsToEarn) ||
              (completed && !nextLevel);
            return (
              <CardPrizeItem
                key={uuid()}
                {...item}
                {...{ completed, current }}
              />
            );
          })
        )}
      </div>
    </Layout>
  );
};

export default LearnAndEarn;

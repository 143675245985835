import useSWR from 'swr';
import { CONFIG_API_URL } from 'global/config';
import { IProductDetailers } from 'models';

// TODO reponse type
export function useProductCategories() {
  const url = `${CONFIG_API_URL}/contentful/product-categories`;
  const { data, error } = useSWR<IProductDetailers, any>(url);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

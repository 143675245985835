import React from 'react';
import ReactDOM from 'react-dom';
import fetcher from 'global/fetcher';
import { SWRConfig } from 'swr';
import { Provider } from 'jotai';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        fetcher: fetcher,
        revalidateIfStale: true,
        revalidateOnFocus: false,
        refreshInterval: 0,
      }}>
      <Provider>
        <App />
      </Provider>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root'),
);

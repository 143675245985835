import { IonText, IonButton } from '@ionic/react';
import { IActivity } from 'models';
import Questions from 'components/Questions/Questions';
import CardBox from 'components/CardBox/CardBox';

interface QuizProps {
  activity: IActivity;
  onComplete: Function;
}

const Quiz: React.FC<QuizProps> = ({ activity, onComplete }) => {
  const { pointValue, quiz } = activity;
  return quiz ? (
    <>
      <IonText color="white">
        <h1 className="ion-text-center">Quiz</h1>
      </IonText>
      <IonText color="white">
        <p className="pb-5">
          Correctly answer this question to earn <b>{activity.pointValue}</b>{' '}
          additional points.
        </p>
      </IonText>

      <Questions {...{ onComplete, pointValue, quiz }} />
      <div className="pb-4"></div>

      {activity.relatedProduct && (
        <CardBox item={{ ...activity.relatedProduct, useIsi: true }} />
      )}
      <div className="ion-text-center mt-6">
        <IonButton routerLink="/dashboard" strong>
          Back to home
        </IonButton>
      </div>
    </>
  ) : null;
};

export default Quiz;

import './PlaceAnOrder.scss';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useFooter } from 'hooks';
import Layout from 'components/Layout/Layout';
import Title from 'components/Title/Title';
import PlaceOrdenForm from 'components/PlaceOrdenForm/PlaceOrdenForm';

const PlaceAnOrder: React.FC = () => {
  const { data: dataFooter } = useFooter();
  const footer = documentToHtmlString(dataFooter?.footer);

  return (
    <Layout copyright={footer}>
      <div className="PlaceAnOrder-page">
        <Title text="Place an order" />
        <PlaceOrdenForm />
      </div>
    </Layout>
  );
};

export default PlaceAnOrder;

import { useForm } from 'react-hook-form';
import {
  IonText,
  IonButton,
  IonSelect,
  IonSelectOption,
  useIonRouter,
} from '@ionic/react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { statesData, updateUserAddress, getUser } from 'global';
import { userAtom } from 'atoms';
import { IAddress } from 'models';
import { useAtom } from 'jotai';
import PlaceholderAddPrefferedShipping from './Placeholder';

const AddPrefferedShippingForm: React.FC = () => {
  const router = useIonRouter();
  const [user, setUser] = useAtom(userAtom);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data: any) => {
    const res = await updateUserAddress(data as IAddress);
    if (res?.ok) {
      const userData = await getUser(user?.email);
      userData && setUser(userData.data);
      router.push(`/manage-profile`, 'forward', 'replace');
    }
  };

  if (isSubmitting) {
    return <PlaceholderAddPrefferedShipping />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="elanco--form">
        <>
          <div className="elanco--item">
            <label id="address">
              Address <IonText color="danger">*</IonText>
            </label>
            <input
              type="text"
              {...register('address', {
                minLength: 5,
                required: true,
              })}
            />

            {errors?.address?.type === 'required' && <ErrorMessage />}
            {errors?.address?.type === 'minLength' && (
              <ErrorMessage text="Min length is 5!" />
            )}
          </div>

          <div className="elanco--item">
            <label id="city">
              City <IonText color="danger">*</IonText>
            </label>
            <input
              // name="zip"
              type="text"
              {...register('city', { required: true })}
            />
            {errors.city && <ErrorMessage />}
          </div>

          <div className="elanco--item">
            <label>
              State <IonText color="danger">*</IonText>
            </label>

            <IonSelect
              interface="popover"
              placeholder=""
              {...register('state_cd', { required: true })}>
              {statesData.map((option: any, i: number) => (
                <IonSelectOption key={i} value={option.value}>
                  {option.name}
                </IonSelectOption>
              ))}
            </IonSelect>

            {errors.state_cd && <ErrorMessage />}
          </div>

          <div className="elanco--item">
            <label id="zip">
              Zip <IonText color="danger">*</IonText>
            </label>
            <input
              // name="zip"
              type="text"
              {...register('zip', { maxLength: 5, required: true })}
            />
            {errors.zip && <ErrorMessage />}
          </div>
        </>
      </div>

      <div className="ion-text-center pt-5 pb-10">
        <IonButton type="submit" strong disabled={!isValid}>
          Submit
        </IonButton>
      </div>
    </form>
  );
};

export default AddPrefferedShippingForm;

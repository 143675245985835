import useSWR from 'swr';
import { CONFIG_API_URL } from 'global/config';
import { IProgress } from 'models';

export function useMyProgress(
) {
    let url = `${CONFIG_API_URL}/user/my-progress`;
    const { data, error } = useSWR<IProgress, any>(url);
    return {
        data,
        isLoading: !error && !data,
        isError: error,
    };
}

import { IonSkeletonText } from '@ionic/react';

const PlaceholderCardBox: React.FC = () => (
  <>
    <IonSkeletonText animated style={{ width: '100%', height: '140px' }} />
    <IonSkeletonText animated style={{ width: '100%', height: '220px' }} />
    <IonSkeletonText animated style={{ width: '100%', height: '220px' }} />
  </>
);

export default PlaceholderCardBox;

import './CardPrize.scss';
import classname from 'classname';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { IonCard, IonCardContent, IonText } from '@ionic/react';
import { IPrize } from 'models';
import { IconBookmark } from 'icons';
export interface CardPrizeProps extends IPrize {
  bookmark?: boolean;
  completed?: boolean;
  current?: boolean;
  raffle?: any;
  onClick?: () => void;
}

const CardPrizeItem: React.FC<CardPrizeProps> = ({
  title,
  level,
  pointsToEarn,
  image,
  hasRaffle = false,
  completed = false,
  current = false,
  raffle,
  onClick,
}) => {
  const classList = classname({
    'elanco-cardprize': true,
    'mb-3': true,
    'is-completed': completed && !current,
    'is-current': current,
  });

  return (
    <IonCard color="white" button className={classList} onClick={onClick}>
      <IonCardContent>
        <div className="elanco-cardprize--content">
          <div>
            <IonText color="secondary">
              <h3 className="elanco-cardprize--level">
                Level {level}
                {hasRaffle ? <IconBookmark /> : null}
              </h3>
              <h4>{title}</h4>
              <p className="elanco-cardprize--points">{pointsToEarn} points</p>
            </IonText>
          </div>
          <div
            className="elanco-cardprize--img"
            style={{ backgroundImage: `url(${image.url})` }}></div>
        </div>
        {/* arrows */}
        {/* <div className="elanco-cardprize--arrows">
          <IconArrow />
        </div>
        <div className="elanco-cardprize--arrows is-right">
          <IconArrow />
        </div> */}
      </IonCardContent>
      {raffle && (!completed || current) ? (
        <div className="elanco-cardprize--raffle">
          <div className="elanco-cardprize--raffle--title mb-2">
            <IconBookmark />
            <p>{raffle.raffleTitle}</p>
          </div>
          {documentToReactComponents(raffle.raffleDescription)}
        </div>
      ) : null}
    </IonCard>
  );
};

export default CardPrizeItem;

import useSWR from 'swr';
import { CONFIG_API_URL } from 'global/config';
import { ISchedulePage } from 'models';

// TODO reponse type
export function useSchedule() {
  const url = `${CONFIG_API_URL}/contentful/schedule`;
  const { data, error } = useSWR<ISchedulePage>(url);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

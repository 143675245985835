import './AddPrefferedShipping.scss';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useFooter } from 'hooks';
import Layout from 'components/Layout/Layout';
import Title from 'components/Title/Title';
import AddPrefferedShippingForm from 'components/MyProfileForm/AddPrefferedShippingForm/AddPrefferedShippingForm';

const AddPrefferedShipping: React.FC = () => {
  const { data: dataFooter } = useFooter();
  const footer = documentToHtmlString(dataFooter?.footer);

  return (
    <Layout copyright={footer}>
      <div className="AddPrefferedShipping-page">
        <Title text="Add Preferred Shipping Address" />

        <AddPrefferedShippingForm />
      </div>
    </Layout>
  );
};

export default AddPrefferedShipping;

import { Switch, Route } from 'react-router-dom';
// Pages
import Welcome from 'pages/Welcome/Welcome';
import Activity from 'pages/Activity/Activity';
import Register from 'pages/Register/Register';
import Login from 'pages/Login/Login';
import ContactUs from 'pages/ContactUs/ContactUs';
import Dashboard from 'pages/Dashboard/Dashboard';
import LearnAndEarn from 'pages/LearnAndEarn/LearnAndEarn';
import Schedule from 'pages/Schedule/Schedule';
import VisitMyElanco from 'pages/VisitMyElanco/VisitMyElanco';
import PlaceAnOrder from 'pages/PlaceAnOrder/PlaceAnOrder';
import Progress from 'pages/Progress/Progress';
import ProductCategories from 'pages/ProductCategories/ProductCategories';
import ProductDetail from 'pages/ProductsDetail/ProductsDetail';
import NotConferenceId from 'pages/NotConferenceId/NotConferenceId';
import ManageProfile from 'pages/ManageProfile/ManageProfile';
import AddPrefferedShipping from 'pages/AddPrefferedShipping/AddPrefferedShipping';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

const pathConference = 'conference/:conferenceId';
const pathActivity = 'activity/:activityId';

const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <PublicRoute
        path={[
          `/welcome/${pathConference}`,
          `/welcome/${pathConference}/${pathActivity}`,
          `/welcome/${pathActivity}`,
          `/welcome/schedule/:conferenceId`,
        ]}
        exact
        component={Welcome}
      />

      <PublicRoute
        path={[
          `/sign-up/${pathConference}`,
          `/sign-up/${pathConference}/${pathActivity}`,
        ]}
        exact
        component={Register}
      />

      <PublicRoute
        path={[
          `/sign-in/${pathConference}`,
          `/sign-in/${pathConference}/${pathActivity}`,
        ]}
        exact
        component={Login}
      />

      <PublicRoute
        isPublic
        path="/products/:productDetailerId"
        exact
        component={ProductDetail}
      />

      <PrivateRoute
        path="/conference/:conferenceId"
        exact
        component={Dashboard}
      />

      <PrivateRoute path={['/dashboard', '/']} exact component={Dashboard} />
      <PrivateRoute path="/products" exact component={ProductCategories} />

      <PrivateRoute path="/contact-us" component={ContactUs} />
      <PrivateRoute path="/visit-myelanco" component={VisitMyElanco} />
      <PrivateRoute path="/learn-and-earn" component={LearnAndEarn} />
      <PrivateRoute path="/place-an-order" component={PlaceAnOrder} />
      <PrivateRoute path="/manage-profile" component={ManageProfile} />
      <PrivateRoute
        path="/add-shipping-address"
        component={AddPrefferedShipping}
      />

      <PrivateRoute path="/progress" component={Progress} />
      <PrivateRoute
        path={['/schedule/:conferenceId', '/schedule']}
        component={Schedule}
      />

      <PrivateRoute
        exact
        path="/conference/:conferenceId/activity/:activityId"
        component={Activity}
      />

      <Route path="/not-found" exact component={NotConferenceId} />
    </Switch>
  );
};

export default AppRoutes;

import { IonSkeletonText } from '@ionic/react';

import './Placeholder.scss';

export const PlaceholderWelcomeHeadline: React.FC = () => (
  <IonSkeletonText
    className="Welcome-Headline-Placeholder"
    style={{ width: '100%', height: '40px' }}
    animated
  />
);

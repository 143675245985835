import './CardBox.scss';
import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
  IonText,
  IonButton,
  useIonAlert,
} from '@ionic/react';

import { useAtomValue } from 'jotai';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useState } from 'react';
import { IconCarrot } from 'icons';
import { IProducts } from 'models';
import { postSendEmail } from 'global';
import { userAtom } from 'atoms';
// import { useSession } from 'hooks/useSession';

interface CardBoxProps {
  isiOpen?: boolean;
  item?: IProducts;
}

const CardBox: React.FC<CardBoxProps> = ({ isiOpen = false, item }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [modal] = useIonAlert();
  const [isi, setIsi] = useState(isiOpen);
  const user = useAtomValue(userAtom);

  const onClickISI = () => {
    setIsi(!isi);
  };

  const onClickOpenPdf = () => {
    window.open(`https:${item?.pdf.url}`, '_blank');
  };

  const onClickSendPdf = async () => {
    if (!item) return;
    const data = {
      name: user?.first_name,
      email: user?.email,
      product_name: item?.name,
      image_url: item?.image?.url || '',
      pdf_url: item?.pdf.url,
    };

    setIsLoading(true);
    const res = await postSendEmail(data);
    setIsLoading(false);

    if (res && res.ok) {
      modal({
        cssClass: 'elanco--alert',
        header: 'Email sent!',
        message: `<p class="ion-text-left">Please check your email and click on the 
        link to download the selected product PDF.</p>`,
        buttons: [{ text: 'Close' }],
      });
    }
  };

  return (
    <div className="elanco-cardbox">
      <IonCard>
        {item?.image ? (
          <IonCardHeader>
            <IonImg src={item.image.url} />
          </IonCardHeader>
        ) : null}
        <IonCardContent className="py-0 mx-0 px-0">
          <div className="px-6 pt-5 pb-6">
            <IonText color="dark">
              <p className="text-smallcopy ion-text-uppercase ion-text-center pb-3">
                <b>Learn More</b>
              </p>
            </IonText>
            <div className="ion-text-center">
              {item?.pdf ? (
                <>
                  <IonButton onClick={onClickOpenPdf} strong>
                    open pdf
                  </IonButton>

                  {user?.email && (
                    <>
                      <div className="pb-2"></div>
                      <IonButton
                        disabled={isLoading}
                        strong
                        fill="outline"
                        onClick={onClickSendPdf}>
                        send pdf to email
                      </IonButton>
                    </>
                  )}
                </>
              ) : null}
              <div className="pb-5"></div>
              {item?.isi && item?.useIsi === true ? (
                <>
                  <IonText color="primary">
                    <div
                      className={`elanco-cardbox--ISI ${
                        isi ? 'is-active' : ''
                      }`}
                      onClick={onClickISI}>
                      <IconCarrot />
                      <p className="text-smallcopy">
                        <b>Safety Information</b>
                      </p>
                    </div>
                  </IonText>
                  {isi ? (
                    <IonText color="secondary" className="ion-text-left">
                      {documentToReactComponents(item.isi)}
                    </IonText>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default CardBox;

import './Welcome.scss';
import { IonContent, IonPage, IonButton, IonText } from '@ionic/react';
import Copyright from 'components/Copyright/Copyright';
import Freeforms from 'components/Freeforms/Freeforms';
import { useParams } from 'react-router';
import { getRouteUrl } from 'global';
import { IconLogo } from 'icons';
import { usePriceVsDonations } from 'hooks/usePriceVsDonations';

import { PlaceholderWelcomeHeadline } from './Placeholder';

const Welcome: React.FC = () => {
  const { conferenceId, activityId } = useParams<any>();
  const priceVsDonation = usePriceVsDonations();

  return (
    <IonPage className="Welcome-page">
      <IonContent className="ion-padding">
        <div className="ion-content--bottom">
          <div>
            <div className="elanco--logo">
              <IconLogo />
            </div>
            <div className="welcome-container">
              {priceVsDonation.isLoading ? (
                <PlaceholderWelcomeHeadline />
              ) : (
                <IonText color="white">
                  <h1 className="ion-text-center">
                    {priceVsDonation.value
                      ? 'Learn & Earn with us'
                      : 'Learn & Give with us'}
                  </h1>
                </IonText>
              )}
              <Freeforms
                name="welcomeDescription"
                placeholderSize="paragraph"
                placeholderClassName="Welcome-Text-Placeholder"
              />
            </div>
            <div className="welcome-content">
              <IonButton
                routerLink={getRouteUrl(conferenceId, activityId, '/sign-up')}
                strong
                style={{ marginBottom: '27px' }}>
                get started
              </IonButton>
              <div className="mb-2">
                <p>Already have an account?</p>
              </div>
              <IonButton
                routerLink={getRouteUrl(conferenceId, activityId, '/sign-in')}
                strong
                fill="outline">
                Sign IN
              </IonButton>
            </div>
          </div>
          <Copyright color="dark" />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;

import { useState } from 'react';
import { IonText, IonButton } from '@ionic/react';
import { IActivity } from 'models';
import Questions from 'components/Questions/Questions';
import CardBox from 'components/CardBox/CardBox';

interface QuizProps {
  activity: IActivity;
  onComplete: Function;
  onProgress: Function;
}

const QuizSet: React.FC<QuizProps> = ({ activity, onComplete, onProgress }) => {
  const { pointValue, quizSet, lastActivity } = activity;
  const totalQuestions = quizSet?.length || 0;
  const [currQuestion, setCurrQuestion] = useState(
    lastActivity && quizSet
      ? quizSet.findIndex((el) => el.id === lastActivity) + 1
      : 0,
  );
  const [activateNextButton, setActivateNextButton] = useState(false);

  const advanceToNextQuestion = () => {
    if (quizSet && currQuestion + 1 < totalQuestions) {
      setCurrQuestion(currQuestion + 1);
      setActivateNextButton(false);
    }
  };

  const questionCompleted = (answered: boolean) => {
    if (quizSet && currQuestion + 1 < totalQuestions) {
      setActivateNextButton(true);
      onProgress(answered, quizSet[currQuestion].id);
    } else if (quizSet) {
      onComplete(answered);
    }
  };

  if (!quizSet && !(quizSet && quizSet[currQuestion])) {
    throw new Error('Quiz set not found');
  }

  const quiz = quizSet[currQuestion].quiz;

  // quiz set related product field takes precedence over child related product
  const product =
    activity.relatedProduct || quizSet[currQuestion].relatedProduct;

  return quiz ? (
    <>
      <IonText color="white">
        <h1 className="ion-text-center">Quiz Set</h1>
      </IonText>
      <IonText color="white">
        <p className="pb-5">
          Correctly answer all of these questions to earn{' '}
          <b>{activity.pointValue}</b> additional points. You can leave and come
          back to finish if needed!
        </p>
      </IonText>

      {quizSet && quizSet[currQuestion].quiz && (
        <Questions
          index={currQuestion}
          total={totalQuestions}
          onComplete={questionCompleted}
          {...{ pointValue, quiz }}
        />
      )}

      <div className="pb-4"></div>

      {product && <CardBox item={{ ...product, useIsi: true }} />}
      <div className="ion-text-center mt-6">
        {currQuestion >= totalQuestions - 1 ? (
          <IonButton routerLink="/dashboard" strong>
            Back to home
          </IonButton>
        ) : (
          <IonButton
            disabled={!activateNextButton}
            onClick={advanceToNextQuestion}
            strong>
            Next
          </IonButton>
        )}
      </div>
    </>
  ) : null;
};

export default QuizSet;

import { IonText } from '@ionic/react';

interface ErrorMessageProps {
  text?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  text = 'This field is required.',
}) => (
  <p className="text-small mt-2">
    <IonText color="danger">{text}</IonText>
  </p>
);

export default ErrorMessage;

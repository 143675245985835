import './Login.scss';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  IonContent,
  IonPage,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonText,
  IonButton,
  useIonRouter,
  IonLoading,
  useIonAlert,
} from '@ionic/react';
import { useAtomValue, useSetAtom } from 'jotai';
import { IconLogo } from 'icons';
import { toast, getUser, getRouteUrl } from 'global';
import { userAtom, redirectAtom } from 'atoms';

// components
import Copyright from 'components/Copyright/Copyright';
import Title from 'components/Title/Title';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { conferenceId, activityId } = useParams<any>();
  const ionRouter = useIonRouter();
  const setUser = useSetAtom(userAtom);
  const redirectUrl = useAtomValue(redirectAtom);
  const [modal] = useIonAlert();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data: any) => {
    if (!data.email) {
      toast("Email can't be blank", 'danger');
    } else {
      setIsLoading(true);
      const res = await getUser(data.email);
      setIsLoading(false);
      if (res && res.ok) {
        setUser(res.data);

        if (activityId) {
          ionRouter.push(
            getRouteUrl(conferenceId, activityId, ''),
            'forward',
            'replace',
          );
        } else {
          ionRouter.push(`/${redirectUrl}`, 'forward', 'replace');
        }
      } else {
        if (res && res.error === 'Email not found!') {
          modal({
            cssClass: 'elanco--alert',
            header: 'Email not registered',
            message:
              'Please register to access the Elanco Hub and participate in our Learn &amp; Give game.',
            buttons: [
              {
                text: 'Close',
                cssClass: 'button-outline',
              },
              {
                text: 'Sign Up',
                cssClass: '',
                handler: (d) => {
                  ionRouter.push(
                    getRouteUrl(conferenceId, activityId, '/sign-up'),
                    'forward',
                    'replace',
                  );
                },
              },
            ],
          });
        }
      }
    }
  };

  return (
    <IonPage className="Login-page">
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton color="white" defaultHref="/" />
        </IonButtons>
      </IonToolbar>

      <div className="elanco--logo">
        <IconLogo />
      </div>

      <IonLoading message="Please Wait..." isOpen={isLoading} />

      <IonContent fullscreen className="ion-padding">
        <div className="ion-content--bottom">
          <div>
            <Title text="Sign in" />
            <IonText color="white">
              <p>
                Enter your email address to access the Elanco Hub and continue
                your learning.
              </p>

              <p>
                Don’t have an account yet? <br />
                Get started by clicking{' '}
                <Link
                  replace
                  to={getRouteUrl(conferenceId, activityId, '/sign-up')}>
                  here
                </Link>
                .
              </p>

              <p>
                Required fields are indicated with an
                <IonText color="danger">*</IonText>.
              </p>
            </IonText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="elanco--form">
                <div className="elanco--item">
                  <label>
                    Email <IonText color="danger">*</IonText>
                  </label>

                  <input
                    type="email"
                    placeholder="example@email.com"
                    {...register('email', {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                  {errors?.email?.type === 'required' && <ErrorMessage />}
                  {errors?.email?.type === 'pattern' && (
                    <ErrorMessage text="Email format is incorrect!" />
                  )}
                </div>
              </div>

              <div
                className="ion-text-center"
                style={{ paddingTop: 25, paddingBottom: 40 }}>
                <IonButton
                  type="submit"
                  strong
                  {...(!isValid ? { disabled: true } : {})}>
                  Submit
                </IonButton>
              </div>
            </form>
          </div>
          <div>
            <Copyright />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;

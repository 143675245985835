import { IonText } from '@ionic/react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { IconCruz } from 'icons';

type ProductItemsProps = {
  index: number;
  register: any;
  errors: any;
  newNewProduct: any;
  products: any;
};

const NewItem = ({ index, errors, register }: any) => {
  const productName = `product_name_${index}`;
  const quantityName = `quantity_value_${index}`;

  return (
    <>
      {index > 1 && <div className="elanco--item-divider"></div>}
      <div className="elanco--item">
        <IonText color="white">
          <p id={productName} className="elanco--item-label">
            Enter product name and dosage
            <span> Example: Entyce 50mL</span>
          </p>
        </IonText>
        <input type="text" {...register(productName, { required: true })} />
        {errors[productName] && <ErrorMessage />}
      </div>
      <div className="elanco--item">
        <IonText color="white">
          <p className="elanco--item-label">
            Enter quantity and container type
            <span>Example: 2 bottles</span>
          </p>
        </IonText>
        <input type="text" {...register(quantityName, { required: true })} />
        {errors[quantityName] && <ErrorMessage />}
      </div>
    </>
  );
};

const ProductItems = ({
  register,
  errors,
  products,
  newNewProduct,
}: ProductItemsProps) => {
  return (
    <>
      <div className="elanco--product-item">
        <div className="elanco--item">
          <label>
            2. What would you like to order? <IonText color="danger">*</IonText>
          </label>
        </div>
        {products.map((item: any, i: number) => (
          <NewItem key={i} index={item} register={register} errors={errors} />
        ))}
      </div>
      <div onClick={newNewProduct} className="elanco--item elanco--addproduct">
        <div className="elanco--icon-cruz">
          <IconCruz w="12" h="12" />
        </div>
        <label>Add Another Product</label>
      </div>
    </>
  );
};

export default ProductItems;

import { IonText } from '@ionic/react';
import iconPencil from 'icons/icon-pencil.svg';
import iconDelete from 'icons/icon-delete.svg';
import { IconCruz } from 'icons';

interface EditHeaderProps {
  editMode: boolean;
  isFormValid?: boolean;
  title: string;
  showDelete?: boolean;
  onToggle: () => void;
  onDelete?: () => void;
}

const IconPencil = (props: any) => (
  <div {...props} className="elanco--form-header-icon">
    <img src={iconPencil} alt="edit" />
  </div>
);

const IconDelete = (props: any) => (
  <div {...props} className="elanco--form-header-icon">
    <img src={iconDelete} alt="delete" />
  </div>
);

const IconClose = (props: any) => (
  <div {...props} className="elanco--form-header-icon is-close">
    <IconCruz w="12" h="12" />
  </div>
);

const EditHeader = ({
  editMode,
  title,
  showDelete,
  onToggle,
  onDelete,
}: EditHeaderProps) => {
  return (
    <div className="elanco--form-header">
      <IonText color="white">{title}</IonText>
      <div className="elanco--form-header-edit">
        {showDelete && !editMode && <IconDelete onClick={onDelete} />}
        {!editMode && <IconPencil onClick={onToggle} />}
        {editMode && <IconClose onClick={onToggle} />}
      </div>
    </div>
  );
};

export default EditHeader;

import './RegisterForm.scss';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  IonText,
  IonItem,
  IonLabel,
  IonButton,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';

import { getClinics, rolesData, statesData } from 'global';
import ClinicModal from 'components/ModalClinics/ModalClinics';
import ControlledCheckbox from 'components/ControlledCheckbox/ControlledCheckbox';

interface RegisterFormProps {
  onSubmit: (data: object) => {};
}

interface ErrorMessageProps {
  text?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  text = 'This field is required.',
}) => (
  <p className="text-small mt-2">
    <IonText color="danger">{text}</IonText>
  </p>
);

const RegisterForm: React.FC<RegisterFormProps> = ({ onSubmit }) => {
  const [clinicNames, setClinicNames] = useState();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm({ mode: 'onChange' });
  const checkBoxChecked = watch('over18', false);
  const watchClinic = watch('clinic');

  const addOwnClinic = watchClinic?.clinic_name === 'Add My Own Clinic';
  const addStudentOrVet =
    watchClinic?.clinic_name === 'Student' ||
    watchClinic?.clinic_name === 'Relief Vet';

  // preload clinic names with 50 options
  useEffect(() => {
    const fetchData = async () => {
      const res = await getClinics();
      if (res && res.ok) {
        setClinicNames(res.data);
      }
    };
    fetchData();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="elanco--form">
        <div className="elanco--item">
          <label id="first_name">
            First Name <IonText color="danger">*</IonText>
          </label>
          <input type="text" {...register('first_name', { required: true })} />
          {errors.first_name && <ErrorMessage />}
        </div>
        <div className="elanco--item">
          <label id="last_name">
            Last Name <IonText color="danger">*</IonText>
          </label>
          <input type="text" {...register('last_name', { required: true })} />
          {errors.last_name && <ErrorMessage />}
        </div>
        <div className="elanco--item">
          <label id="email">
            Email <IonText color="danger">*</IonText>
          </label>
          <input
            type="email"
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors?.email?.type === 'required' && <ErrorMessage />}
          {errors?.email?.type === 'pattern' && (
            <ErrorMessage text="Email format is incorrect!" />
          )}
        </div>

        <div className="elanco--item">
          <label>
            Your Role <IonText color="danger">*</IonText>
          </label>

          <IonSelect
            interface="popover"
            placeholder=""
            {...register('your_role', { required: true })}>
            {rolesData.map((option: any, i: number) => (
              <IonSelectOption key={i} value={option.value}>
                {option.name}
              </IonSelectOption>
            ))}
          </IonSelect>

          {errors.your_role && <ErrorMessage />}
        </div>

        <Controller
          name="clinic"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <ClinicModal {...{ onChange, onBlur, value }} data={clinicNames} />
          )}
        />

        {addOwnClinic && (
          <div className="elanco--item">
            <label id="ownClinic">
              Enter Your Clinic Name <IonText color="danger">*</IonText>
            </label>
            <input
              type="text"
              {...register('ownClinic', { required: addOwnClinic })}
            />
            {errors.ownClinic && <ErrorMessage />}
          </div>
        )}

        {(addStudentOrVet || addOwnClinic) && (
          <>
            <div className="elanco--item">
              <label id="address-line-1">
                Address <IonText color="danger">*</IonText>
              </label>
              <input
                // name="zip"
                type="text"
                {...register('addressLine', {
                  minLength: 5,
                  required: addOwnClinic,
                })}
              />

              {errors?.addressLine?.type === 'required' && <ErrorMessage />}
              {errors?.addressLine?.type === 'minLength' && (
                <ErrorMessage text="Min length is 5!" />
              )}
            </div>

            <div className="elanco--item">
              <label id="city">
                City <IonText color="danger">*</IonText>
              </label>
              <input
                // name="zip"
                type="text"
                {...register('city', { required: addOwnClinic })}
              />
              {errors.city && <ErrorMessage />}
            </div>

            <div className="elanco--item">
              <label>
                State <IonText color="danger">*</IonText>
              </label>

              <IonSelect
                interface="popover"
                placeholder=""
                {...register('state', { required: true })}>
                {statesData.map((option: any, i: number) => (
                  <IonSelectOption key={i} value={option.value}>
                    {option.name}
                  </IonSelectOption>
                ))}
              </IonSelect>

              {errors.state && <ErrorMessage />}
            </div>

            <div className="elanco--item">
              <label id="zip">
                Clinic Zip <IonText color="danger">*</IonText>
              </label>
              <input
                maxLength={5}
                type="text"
                {...register('zip', { maxLength: 5, required: addOwnClinic })}
              />
              {errors.zip && <ErrorMessage />}
            </div>
          </>
        )}
      </div>

      <IonText color="white" className="text-small">
        Your privacy is important to us. To find out more about the information
        that Elanco may collect, how we use it, how we protect it, and your
        rights and choices with respect to your personal data,{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.elancostatements.com/en/privacy/elanco-com">
          click here
        </a>
        .
      </IonText>

      <IonItem className="text-small">
        <IonLabel color="white">
          I certify that I am at least 18 years old.
          <IonText color="danger">*</IonText>
        </IonLabel>
        <ControlledCheckbox name="over18" control={control} required={true} />
      </IonItem>

      <div className="ion-text-center pt-5 pb-10">
        <IonButton
          type="submit"
          strong
          {...(!isValid || isSubmitting || checkBoxChecked !== true
            ? { disabled: true }
            : {})}>
          Submit
        </IonButton>
      </div>
    </form>
  );
};

export default RegisterForm;

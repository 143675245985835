import { IAddress, IPoints, IUser } from 'models';
import { CONFIG_API_URL } from './config';
import { getRequest, sendRequest } from './http';

/**
 * Create new elanco users
 *
 * @param user
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function postUser(user: IUser) {
  return sendRequest(`${CONFIG_API_URL}/user`, user);
}

/**
 * Updates elanco users
 *
 * @param user
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function updateUser(user: any) {
  return sendRequest(`${CONFIG_API_URL}/user`, user, 'PUT');
}
/**
 * Updates elanco users preffered address
 *
 * @param user
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function updateUserAddress(address: IAddress, method = 'POST') {
  return sendRequest(`${CONFIG_API_URL}/user/address`, address, method);
}

/**
 * Updates elanco users points
 *
 * @param user
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function postUserPoints(points: IPoints) {
  return sendRequest(`${CONFIG_API_URL}/user/points`, points);
}

/**
 * Save progress on quiz sets
 *
 * @param user
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function postUserLastActivity(lastActivity: any) {
  return sendRequest(`${CONFIG_API_URL}/user/last-activity`, lastActivity);
}

/**
 * Check if user exist, if exist return user info from the db
 *
 * @param user
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function getUser(email = '') {
  return getRequest(
    `${CONFIG_API_URL}/user/login?email=${encodeURIComponent(email)}`,
  );
}

/**
 * Get all clinics names on the db with a limit of 50 names
 *
 * @param user
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function getClinics(name = '', limit = 50) {
  return getRequest(`${CONFIG_API_URL}/clinic?name=${name}&limit=${limit}`);
}

/**
 * Get all clinics names on the db with a limit of 50 names
 *
 * @param user
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function getActivity(activityId: string) {
  return getRequest(`${CONFIG_API_URL}/user/activity/${activityId}`);
}

/**
 * Call api to send a dymanic email to the clinic email addresses.
 *
 * @param conference_contact_email  current conference contact email
 * @param customer_name  customer user name plus last name
 * @param customer_zipcode  customer user zipcode or other zipcode
 * @param customer_email customer email
 * @param customer_clinic_name clinic name or other clinic name
 * @param customer_interested customer interested selection
 *
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function postSendContact(data: any) {
  return sendRequest(`${CONFIG_API_URL}/email/contact-rep`, data);
}

/**
 * Call api to send a dymanic email to the user with custom name, image url and pdf link.
 *
 * @param name  user first name
 * @param product_name  product name
 * @param email user email
 * @param image_url logo product
 * @param pdf_url pdf product
 *
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function postSendEmail(data: any) {
  return sendRequest(`${CONFIG_API_URL}/email`, data);
}

/**
 * Call api to save user order.
 *
 * @param phone_number user phone number
 * @param preferred_order prefered way order
 * @param products array of products [{ name: string, quantity: number }]
 * @param representative representative name
 *
 * @returns Promise with ok property set to a boolean and data { ok, data }
 */
export async function postPlaceOrder(data: any) {
  return sendRequest(`${CONFIG_API_URL}/user/place-an-order`, data);
}

import useSWR from 'swr';
import { CONFIG_API_URL } from 'global/config';
import { IFooter } from 'models';

export function useFooter(dynamicFooter?: string) {
  let url = `${CONFIG_API_URL}/contentful/footer`;

  if (dynamicFooter) {
    url += `?dynamic=${dynamicFooter}`;
  }

  const { data, error } = useSWR<IFooter, any>(url);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

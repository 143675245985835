import './Questions.scss';
import { useEffect, useState } from 'react';
import { IonText } from '@ionic/react';
import { IQuiz } from 'models';
import Confetti from 'components/Confetti/Confetti';
import QuestionsItem from './QuestionItem';

interface QuestionDetailProps {
  quiz: IQuiz;
  pointValue?: number;
  onComplete: Function;
  index?: number;
  total?: number;
}

const Questions: React.FC<QuestionDetailProps> = ({
  quiz: { question, answers, reference },
  pointValue,
  onComplete,
  index = 0,
  total = 1,
}) => {
  const correctAnswer = answers.find((answer) => answer.isCorrect)?.id;
  const [answeredId, setAnsweredId] = useState('');
  const answeredCorrectly = correctAnswer === answeredId;
  const isQuizSet = total && total > 1;
  const isLastQuestion = index === total - 1;

  const onClickAnswer = (id: string) => {
    setAnsweredId(id);
    if (correctAnswer === id) {
      onComplete(true);
    }
  };

  // When the question changes, reset correct answer id back to empty string
  useEffect(() => {
    setAnsweredId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  return (
    <div className="elanco--questions">
      <IonText color="success">
        <p className="text-smallcopy ion-text-uppercase">
          question {isQuizSet && `${index + 1} of ${total}`}
        </p>
      </IonText>
      <IonText color="white">
        <h3 className="">{question}</h3>
      </IonText>

      {reference && (
        <IonText color="white">
          <p className="text-smallcopy">{reference}</p>
        </IonText>
      )}

      <div className="pb-5"></div>

      {answers.map((item: any, i: number) => (
        <QuestionsItem
          disabled={answeredCorrectly}
          key={item.id}
          {...item}
          handleClick={onClickAnswer}
          {...{ answeredId }}
        />
      ))}

      <div className="pb-3" />
      {answeredCorrectly === true && (
        <>
          {isLastQuestion ? (
            <>
              <Confetti />
              <IonText color="success">
                <p className="ion-text-center">
                  <b>Correct! You’ve earned {pointValue} points!</b>
                </p>
              </IonText>
            </>
          ) : (
            <IonText color="success">
              <p className="ion-text-center">
                <b>Correct! Tap the next button to continue.</b>
              </p>
            </IonText>
          )}
        </>
      )}

      {answeredCorrectly === false && answeredId && (
        <IonText color="danger">
          <p className="ion-text-center">
            <b>Incorrect answer. Please try again.</b>
          </p>
        </IonText>
      )}

      {isLastQuestion && (
        <IonText color="white">
          <p className=" ion-text-center">
            To Learn &amp; Earn even more, please visit the Elanco booth.
          </p>
        </IonText>
      )}
    </div>
  );
};

export default Questions;

import { Control, Controller } from "react-hook-form";
import {
	IonCheckbox,
} from '@ionic/react';

interface CheckboxProps {
	control: Control;
	name: string;
	required?: boolean;
}

const ControlledCheckbox: React.FC<CheckboxProps> = ({ name, control, required = false }) => (
	<Controller
		name={name}
		control={control}
		rules={{ ...{required} }}
		render={({ field: { onChange, onBlur, ref } }) => (
		<IonCheckbox
			slot="start"
			onIonChange={(e) => onChange(e.detail.checked)}
			onIonBlur={onBlur}
			value={name}
			ref={ref}
		/>
		)}
	/>
)

export default ControlledCheckbox;
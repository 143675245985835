import { IonSpinner, IonText } from '@ionic/react';
import './Copyright.scss';
import { useFooter } from '../../hooks';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

interface CopyrightProps {
  color?: string;
  text?: string;
}

const Copyright: React.FC<CopyrightProps> = ({
  color = 'white',
  text = '',
}) => {
  const { data, isError, isLoading } = useFooter();

  if (isError) {
    return null;
  }

  if (isLoading && !data) {
    return (
      <IonSpinner color={color} style={{ margin: '0 auto' }} name="dots" />
    );
  }

  const globalFooter = documentToHtmlString(data?.footer);

  return (
    <footer>
      <IonText
        className="elanco-copyright"
        color={color}
        dangerouslySetInnerHTML={{
          __html: text.length > 0 ? text : globalFooter,
        }}></IonText>
    </footer>
  );
};

export default Copyright;

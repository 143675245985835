import { useEffect, useState } from 'react';
import { useIonModal } from '@ionic/react';
import { IClinic, IOtherClinic, IUser } from 'models';
import { getClinics, updateUser } from 'global';
import { ClinicModalBody } from 'components/ModalClinics/ModalClinics';
import CustomClinicForm from '../CustomClinicForm/CustomClinicForm';
import EditHeader from '../EditHeader';
import PlaceholderClinicForm from './Placeholder';

interface UserProps {
  user: IUser;
}

const ClinicForm = ({ user }: UserProps) => {
  // get clinics info
  const clinic = user.clinics;
  const customClinic: IOtherClinic = {
    state_cd: user?.state_cd,
    clinic_other_name: user?.clinic_other_name,
    clinic_other_zip: user?.clinic_other_zip,
    address_line_1: user?.address_line_1,
    address_city: user?.address_city,
  };

  const [clinicItem, setClinicItem] = useState<IClinic | undefined>(clinic);
  const [otherClinic, setOtherClinic] = useState<IOtherClinic | undefined>(
    customClinic,
  );
  const [clinicNames, setClinicNames] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [customMode, setCustomMode] = useState(user.clinic_crm_id === null);

  // Preload clinic names with 50 options
  useEffect(() => {
    const fetchData = async () => {
      const res = await getClinics();
      if (res && res.ok) {
        setClinicNames(res.data);
      }
    };
    fetchData();
  }, []);

  const onSelectItem = (data: IClinic) => {
    if (data.crm_id) {
      onSaveClinic(data);
      setCustomMode(false);
    } else {
      setEditMode(true);
      setCustomMode(true);
      setOtherClinic({
        ...customClinic,
        clinic_other_name: data.clinic_name,
      });
    }
  };

  const onSaveClinic = async (data: IClinic) => {
    // show skaleton animation
    setIsLoaded(true);

    // reset values for custom clinic
    const res = await updateUser({
      clinic_crm_id: data.crm_id,
      clinic_other_name: null,
      clinic_other_zip: null,
      address_line_1: null,
      address_city: null,
      state_cd: null,
    });

    // Save clinics and hide skaleton animation
    if (res?.ok) {
      setOtherClinic(undefined);
      setClinicItem(data);
      setEditMode(false);
      setIsLoaded(false);
    }
  };

  const onSaveOtherClinic = async (data: IOtherClinic) => {
    setOtherClinic(data);
    setEditMode(false);
    setClinicItem(undefined);
  };

  // Dismiss modal and reset edit mode
  const onHandleDismissModal = () => {
    dismiss();
    setEditMode(false);
  };

  // Create clinic modal
  const [present, dismiss] = useIonModal(ClinicModalBody, {
    onDismiss: onHandleDismissModal,
    onSelectItem: onSelectItem,
    clinicNames: clinicNames,
  });

  // Toggle edit mode
  const onToggleEdit = () => {
    present();
  };

  return (
    <div className="elanco--form-clinic elanco--form-myprofile">
      <EditHeader
        title={'Clinic Information'}
        isFormValid={true}
        editMode={editMode}
        onToggle={onToggleEdit}
      />

      {isLoaded && <PlaceholderClinicForm />}

      {!isLoaded && !editMode && !customMode && (
        <div className="elanco--item no-m-bottom">
          <label>Clinic Name and Address</label>
          <div className="form-myprofile--label ion-text-capitalize">
            {clinicItem?.clinic_name.toLocaleLowerCase()} <br />
            {clinicItem?.address_1.toLocaleLowerCase()} <br />
            {clinicItem?.city.toLocaleLowerCase()}, {clinicItem?.state_cd}{' '}
            {clinicItem?.zip}
          </div>
        </div>
      )}

      {!isLoaded && editMode && customMode && (
        <CustomClinicForm
          clinic={otherClinic as IOtherClinic}
          setEditMode={setEditMode}
          onCancel={present}
          onSaveForm={onSaveOtherClinic}
        />
      )}

      {!isLoaded && !editMode && customMode && (
        <div className="elanco--item no-m-bottom">
          <label>Custom Clinic Name and Address</label>
          <div className="form-myprofile--label ion-text-capitalize">
            {otherClinic?.clinic_other_name?.toLocaleLowerCase()} <br />
            {otherClinic?.address_line_1?.toLocaleLowerCase()} <br />
            {otherClinic?.address_city?.toLocaleLowerCase()},{' '}
            {otherClinic?.state_cd} {otherClinic?.clinic_other_zip}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClinicForm;

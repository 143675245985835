import './ContactUs.scss';
import {
  IonText,
  IonItem,
  IonLabel,
  IonButton,
  useIonAlert,
  useIonRouter,
  IonLoading,
} from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAtomValue } from 'jotai';
import Layout from 'components/Layout/Layout';
import GameScore from 'components/GameScore/GameScore';
import ControlledCheckbox from 'components/ControlledCheckbox/ControlledCheckbox';
import { postSendContact } from 'global';
import { userAtom } from 'atoms';
import { useConference } from 'hooks';

const ContactUs: React.FC = () => {
  const [modal] = useIonAlert();
  const ionRouter = useIonRouter();
  const user = useAtomValue(userAtom);
  const { data } = useConference();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleSubmit, watch, control } = useForm({ mode: 'onChange' });
  const checkBoxChecked = watch('consent', false);
  const interestCheckboxes = watch('interests', {});
  const interestChecked = Object.values(interestCheckboxes).some((val) => val);

  const onSubmit = async (formData: any) => {
    let customer_interested = [];
    for (const key in formData.interests) {
      if (formData.interests[key]) {
        const str = key.replaceAll('_', ' ');
        customer_interested.push(str.charAt(0).toUpperCase() + str.slice(1));
      }
    }

    const params = {
      conference_contact_email: '',
      customer_name: user?.first_name + ' ' + user?.last_name,
      customer_zipcode: user?.clinics
        ? user.clinics.zip
        : user?.clinic_other_zip,
      customer_email: user?.email,
      customer_clinic_name: user?.clinics
        ? user.clinics.clinic_name
        : user?.clinic_other_name,
      customer_interested,
    };

    // if we have a contactEmail
    if (data?.contactEmail) {
      params.conference_contact_email = data.contactEmail;
    }

    setIsLoading(true);
    const res = await postSendContact(params);
    setIsLoading(false);

    if (res && res.ok) {
      modal({
        cssClass: 'elanco--alert',
        header: 'Thank you!',
        message: `<p class="ion-text-center">Your request has been sent and you will
            hear from an Elanco representative as soon as possible.</p>`,
        buttons: [
          {
            text: 'Back to home',
            handler: () => ionRouter.push('/dashboard', 'forward', 'replace'),
          },
        ],
        onDidDismiss: () => console.log('did dismiss'),
      });
    }
  };

  return (
    <Layout>
      <div className="ContactUs-page">
        <IonLoading message="Please Wait..." isOpen={isLoading} />
        <GameScore />

        <IonText color="white">
          <h1 className="ion-text-center">Contact a rep</h1>
        </IonText>

        <IonText color="white">
          <p className="pb-2">
            Contact an Elanco sales representative to learn more about our
            products, our pursuit of a companionship enriching life, or any
            other questions. Fill out the form below and someone will get back
            to you as soon as possible.
          </p>
        </IonText>

        <form onSubmit={handleSubmit(onSubmit)}>
          <IonText color="white">
            <h2>I am interested in:</h2>
          </IonText>

          <IonItem>
            <IonLabel color="white">General inquiry</IonLabel>
            <ControlledCheckbox
              name="interests.general_inquiry"
              control={control}
            />
          </IonItem>

          <IonItem>
            <IonLabel color="white">Placing an order</IonLabel>
            <ControlledCheckbox
              name="interests.placing_an_order"
              control={control}
            />
          </IonItem>

          <IonItem>
            <IonLabel color="white">Product questions</IonLabel>
            <ControlledCheckbox
              name="interests.product_questions"
              control={control}
            />
          </IonItem>

          <IonItem>
            <IonLabel color="white">Finding my rep</IonLabel>
            <ControlledCheckbox
              name="interests.finding_my_rep"
              control={control}
            />
          </IonItem>

          <IonText color="white">
            <p className="pb-2 pt-5">
              Should you need immediate assistance, call{' '}
              <a className="phone-link" href="tel:18773526261">
                +1 877 352-6261
              </a>
              .
            </p>
          </IonText>

          <IonItem className="text-small">
            <IonText className="ion-text-wrap" color="white">
              As an animal health professional, I agree to be contacted by an
              Elanco representative. I understand my information will be handled
              in accordance with the company's{' '}
              <a
                color="white"
                className="inline-link"
                target="_blank"
                href="https://www.elancostatements.com/en/privacy/elanco-com"
                rel="noreferrer">
                Privacy Notice
              </a>
              .<IonText color="danger">*</IonText>
            </IonText>
            <ControlledCheckbox
              name="consent"
              control={control}
              required={true}
            />
          </IonItem>

          <div className="ion-text-center my-5">
            <IonButton
              type="submit"
              strong
              {...(!interestChecked || !checkBoxChecked
                ? { disabled: true }
                : {})}>
              Submit
            </IonButton>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default ContactUs;

// import useSWR from 'swr';
// import { CONFIG_API_URL } from 'global/config';
import { IActivity } from 'models';
import { getActivity } from 'global';
import { useState, useEffect } from 'react';

// export function useUserActivity(activityId: string) {
//   let url = `${CONFIG_API_URL}/user/activity/${activityId}`;
//   const { data, error } = useSWR<IActivity, any>(activityId ? url : null);
//   return {
//     data,
//     isLoading: !error && !data,
//     isError: error,
//   };
// }

export const useUserActivity = (activityId: string) => {
  const [data, setData] = useState<IActivity>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const res = await getActivity(activityId);
        if (res && res.ok) {
          setData(res.data);
        }
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [activityId]);

  return [{ data, isLoading, isError }];
};

import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router';
import { useAtomValue } from 'jotai';
import { conferenceIdAtom, isAuthenticatedAtom } from 'atoms';

interface PublicRouteProps extends RouteProps {
  component: any;
  isPublic?: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  component: Component,
  isPublic,
  ...rest
}) => {
  const isAuthenticated = useAtomValue(isAuthenticatedAtom);
  const conferenceId = useAtomValue(conferenceIdAtom);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated && !isPublic) {
          return <Redirect to="/dashboard" />;
        }

        if (isPublic) {
          return <Component {...props} />;
        }

        if (!isAuthenticated && conferenceId === null) {
          return <Redirect to="/not-found" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PublicRoute;

import useSWR from 'swr';
import { CONFIG_API_URL } from 'global/config';
import { ILearnAndEarn } from 'models';

// TODO reponse type
export function useLearnAndEarn() {
  const url = `${CONFIG_API_URL}/contentful/learn-and-earn`;
  const { data, error } = useSWR<ILearnAndEarn, any>(url);

  return {
    data,
    isLoading: !error && !data,
    isError: error,
  };
}

import { IonText, IonButton, IonSpinner } from '@ionic/react';
import './Completed.scss';
import { usePriceVsDonations } from 'hooks/usePriceVsDonations';

interface CompletedProps {
  title: string;
}

const Completed: React.FC<CompletedProps> = ({ title }) => {
  const priceVsDonation = usePriceVsDonations();

  return (
    <div className="Completed">
      <IonText color="white">
        <h1 className="ion-text-center">
          Thank you for participating in Elanco's{' '}
          {priceVsDonation.isLoading ? (
            <IonSpinner color="white" name="dots" />
          ) : priceVsDonation.value ? (
            'Learn & Earn game!'
          ) : (
            'Learn & Give game!'
          )}
        </h1>
      </IonText>
      <div className="pb-12" />
      <IonText color="white">
        <h2 className="ion-text-center">
          You have already earned points for {title}.
        </h2>
      </IonText>
      <div className="ion-text-center pt-12">
        <IonButton routerLink="/dashboard" strong>
          Back to home
        </IonButton>
      </div>
    </div>
  );
};

export default Completed;

import './GameScore.scss';
import { IonIcon, IonSpinner, useIonRouter } from '@ionic/react';
import { useAtomValue } from 'jotai';
import iconGift from 'icons/icon-gitf.svg';
import { gamesScoreAtom } from 'atoms';
import { usePriceVsDonations } from 'hooks/usePriceVsDonations';

interface GameScoreProps {
  link?: string;
  showLearn?: boolean;
}

const GameScore: React.FC<GameScoreProps> = ({
  showLearn,
  link = '/learn-and-earn',
}) => {
  const points = useAtomValue(gamesScoreAtom);
  const router = useIonRouter();
  const priceVsDonation = usePriceVsDonations();

  const onClick = () => {
    router.push(link);
  };

  return (
    <div className="elanco--game-score">
      <IonIcon slot="start" size="large" src={iconGift} />
      {priceVsDonation.isLoading ? (
        <IonSpinner color="black" name="dots" />
      ) : (
        <h4>
          <span>
            {priceVsDonation.value ? 'Learn & Earn' : ' Learn & Give'}
            <br />
          </span>
          Game Score:
        </h4>
      )}
      <p>{points || 0}</p>
      {showLearn &&
        (priceVsDonation.isLoading ? (
          <IonSpinner color="black" name="dots" />
        ) : (
          <div className="game-score--learn" onClick={onClick}>
            <span>
              {`See ${priceVsDonation.value ? 'Prize' : 'Donation'} Level`}
            </span>
          </div>
        ))}
    </div>
  );
};

export default GameScore;

import './Schedule.scss';
import { IonText, IonAccordionGroup, IonSkeletonText } from '@ionic/react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { ISchedule } from 'models';
import { useSchedule } from 'hooks/useSchedule';
import { useFooter } from 'hooks';
import Layout from 'components/Layout/Layout';
import GameScore from 'components/GameScore/GameScore';
import Title from 'components/Title/Title';
import AccordionItem from 'components/AccordionItem/AccordionItem';

const RenderAccordion = ({ data }: { data: any }) => (
  <IonAccordionGroup className="mt-8">
    {data.map((item: ISchedule) => {
      const dateString = item.date + ' 12:00:00';
      const dateTime = new Date(dateString.replace(' ', 'T') + 'Z');
      const date = dateTime.toLocaleString('default', {
        month: 'long',
        weekday: 'long',
        day: '2-digit',
      });

      const detail = documentToHtmlString(item.eventDetails).replace(
        /\n/g,
        `</br>`,
      );

      return (
        <AccordionItem key={`${item.date}`} id={`${item.date}`} title={date}>
          <ul className="list--plain">
            <li className="text-medium mt-3">
              <IonText color="secondary">
                <IonText
                  color="secondary"
                  dangerouslySetInnerHTML={{
                    __html: detail,
                  }}></IonText>
              </IonText>
            </li>
          </ul>
        </AccordionItem>
      );
    })}
  </IonAccordionGroup>
);

const Schedule: React.FC = () => {
  const { data: dataFooter } = useFooter('scheduleFooter');
  const { data, isLoading, isError } = useSchedule();

  const footer = documentToHtmlString(dataFooter?.footer);

  return (
    <Layout copyright={footer}>
      <div className="Schedule-page">
        <GameScore />
        <Title text="Event schedule" />
        {isLoading ? (
          <div className="mt-8">
            <IonSkeletonText
              className="schedule-event-placeholder"
              style={{ width: '100%', height: '50px' }}
              animated
            />
            <IonSkeletonText
              className="schedule-event-placeholder"
              style={{ width: '100%', height: '50px' }}
              animated
            />
            <IonSkeletonText
              className="schedule-event-placeholder"
              style={{ width: '100%', height: '50px' }}
              animated
            />
          </div>
        ) : !data && isError ? (
          <IonText color="white">No schedule content</IonText>
        ) : (
          <RenderAccordion data={data} />
        )}
      </div>
    </Layout>
  );
};

export default Schedule;

import { IonText, IonButton } from '@ionic/react';
import './PointsEarned.scss';

import Trophy from 'components/Trophy/Trophy';
import { IconCruz } from 'icons';
import { useEffect } from 'react';

interface PointsEarnedProps {
  title: string;
  points: number;
  onComplete: Function;
}

const PointsEarned: React.FC<PointsEarnedProps> = ({
  title,
  points,
  onComplete,
}) => {
  useEffect(() => {
    onComplete(true);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="PointsEarned">
      <Trophy />

      <div className="pb-3" />

      <IonText color="white">
        <h1 className="ion-text-center px-5">
          Thank you for participating in Elanco's Learn & Earn game with us!
        </h1>
      </IonText>
      <div className="pb-20" />
      <div className="pb-20" />
      <div className="pb-12" />
      <IonText color="white">
        <h2 className="ion-text-center">Great job! You’ve earned</h2>
      </IonText>

      <IonText color="success">
        <div className="PointsEarned-total">
          <h3 className="ion-text-center">
            <IconCruz />
            <span>{points}</span>
          </h3>
          <span>
            <b>POINTS</b>
          </span>
        </div>
      </IonText>

      <div className="pb-4" />
      <IonText color="white">
        <h4 className="ion-text-center text-h2 px-8">for {title}.</h4>
      </IonText>
      <div className="ion-text-center">
        <IonButton routerLink="/dashboard" strong>
          Back to home
        </IonButton>
      </div>
    </div>
  );
};

export default PointsEarned;

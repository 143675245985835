import { IonText, IonList, useIonRouter, IonSkeletonText } from '@ionic/react';
import { useConference } from 'hooks';
import Layout from 'components/Layout/Layout';
import CardButton from 'components/CardButton/CardButton';
import GameScore from 'components/GameScore/GameScore';
import iconGame from 'icons/icon-game.svg';
import iconEvents from 'icons/icon-events.svg';
import iconProducts from 'icons/icon-products.svg';
import iconPlaceOrder from 'icons/icon-place-order.svg';
import iconVisitMyElanco from 'icons/icon-visit-my-elanco.svg';
import { usePriceVsDonations } from 'hooks/usePriceVsDonations';

const Dashboard: React.FC = () => {
  const priceVsDonation = usePriceVsDonations();
  const ionRouter = useIonRouter();
  const { data, isLoading } = useConference();

  const title = 'Welcome to ' + data?.showTitle;

  const navigationItems = [
    {
      id: 'game',
      isLoading: priceVsDonation.isLoading,
      title: priceVsDonation.value ? 'Learn & Earn game' : 'Learn & Give game',
      description: `Come fetch points for ${
        priceVsDonation.value ? 'prizes' : 'donations'
      }`,
      icon: iconGame,
      link: '/learn-and-earn',
    },
    {
      id: 'event-schedule',
      title: 'Event schedule',
      description: 'For all Elanco events',
      icon: iconEvents,
      link: '/schedule',
    },
    {
      id: 'product-detailers',
      title: 'Product detailers',
      description: 'Detailed product information',
      icon: iconProducts,
      link: '/products',
    },
    {
      id: 'place-an-order',
      title: 'Place an order',
      description: 'Start your order',
      icon: iconPlaceOrder,
      link: '/place-an-order',
    },
    {
      id: 'visit-myelanco.com',
      title: 'Visit myElanco.com',
      description: 'Continue your learning',
      icon: iconVisitMyElanco,
      link: '/visit-myelanco',
    },
    // {
    //   title: 'Contact a rep',
    //   description: 'Connect with an Elanco representative',
    //   icon: iconContact,
    //   link: '/contact-us',
    // },
  ];

  return (
    <Layout backButton={false}>
      <div className="Dashboard-page">
        <GameScore showLearn />
        {isLoading && !data ? (
          <IonSkeletonText
            className="Dashboard-Headline-Placeholder"
            style={{ width: '100%', height: '40px' }}
            animated
          />
        ) : (
          <IonText color="white">
            <h1 className="ion-text-center">{title}</h1>
          </IonText>
        )}
        <IonText color="white">
          <p className="ion-text-center px-10">
            We invite you to learn with us every step of the way.
          </p>
        </IonText>
        <IonList>
          {navigationItems.map((item) => (
            <CardButton
              key={item.id}
              title={item.title}
              description={item.description}
              icon={item.icon}
              onClick={() => ionRouter.push(item.link)}
              isLoading={item.isLoading}
            />
          ))}
        </IonList>
      </div>
    </Layout>
  );
};

export default Dashboard;

import { IonSpinner, IonText } from '@ionic/react';
import { useFreeforms } from 'hooks';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import PlaceholderFreeform, {
  PlaceholderFreeformHeadline,
} from './Placeholder';
import Title from 'components/Title/Title';
import { IFreeforms } from 'models';

interface FreeformsProps {
  name: keyof IFreeforms;
  placeholderSize?: 'headline' | 'paragraph' | 'spinner';
  placeholderClassName?: string;
}

const Freeforms: React.FC<FreeformsProps> = ({
  name,
  placeholderSize = 'headline',
  placeholderClassName,
}) => {
  const { data, isLoading } = useFreeforms();

  if (isLoading && !data) {
    switch (placeholderSize) {
      case 'headline':
        return <PlaceholderFreeformHeadline className={placeholderClassName} />;
      case 'spinner':
        return <IonSpinner color="black" name="dots" />;
      default:
        return <PlaceholderFreeform className={placeholderClassName} />;
    }
  }

  if (!data) {
    return null;
  }

  switch (name) {
    case 'welcomeDescription':
      return (
        <IonText
          color={'white'}
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(data.welcomeDescription),
          }}></IonText>
      );
    case 'laegDescription':
      return (
        <IonText
          color={'white'}
          style={{ marginBottom: 30 }}
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(data.laegDescription),
          }}></IonText>
      );
    case 'laegRules':
      return <span>{data.laegRules}</span>;
    case 'laegPriceTitle':
      return <Title text={data.laegPriceTitle} Tag="h2" />;
    case 'laegPricesDescription':
      return (
        <IonText
          color={'white'}
          style={{ marginBottom: 30 }}
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(data.laegPricesDescription),
          }}></IonText>
      );
  }

  return null;
};

export default Freeforms;

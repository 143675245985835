import { IonSkeletonText } from '@ionic/react';

const PlaceholderCustomClinicForm: React.FC = () => (
  <>
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '55px', marginBottom: '10px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '60px', marginBottom: '10px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '55px', marginBottom: '10px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '60px', marginBottom: '10px' }}
    />
    <IonSkeletonText
      animated
      style={{ width: '100%', height: '70px', marginBottom: '10px' }}
    />
  </>
);

export default PlaceholderCustomClinicForm;

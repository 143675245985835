import { useEffect, useState } from 'react';
import {
  IonItem,
  IonButtons,
  IonContent,
  IonText,
  IonIcon,
  IonHeader,
  IonList,
  IonPage,
  IonSearchbar,
  IonToolbar,
  useIonModal,
} from '@ionic/react';
import throttle from 'lodash.throttle';
import { closeCircle, addCircle } from 'ionicons/icons';
import { getClinics } from 'global';
import './ModalClinics.scss';
import { useConfirmAddress } from 'hooks/useConfirmAddress';
import { IClinic } from 'models';

export const ClinicModalBody: React.FC<{
  onDismiss: () => void;
  onSelectItem: (clinic: IClinic) => void;
  clinicNames: IClinic[];
}> = ({ onSelectItem, onDismiss, clinicNames }) => {
  const [clinics, setClinics] = useState(clinicNames);

  // TODO: Add real type ahead functionality
  let suggestions: IClinic[];
  const handleTypeAhead = async (e: any) => {
    const query = e.target.value;

    // query cames sort asc
    const res = await getClinics(query);

    if (res && res.ok) {
      clinicNames = res.data;
      suggestions = clinicNames.map((clinic: IClinic, i: number) => {
        const index = clinicNames.findIndex((c, num) => {
          if (num !== i && c.clinic_name === clinic.clinic_name) {
            return true;
          }
          return false;
        });
        if (index !== -1) {
          clinic.showAddress = true;
        }

        return clinic;
      });

      setClinics(suggestions);
    }
  };

  const throttleTypeAhead = throttle(handleTypeAhead, 400);

  useEffect(() => {
    return () => {
      throttleTypeAhead.cancel();
    };
  }, [throttleTypeAhead]);

  return (
    <IonPage className="elanco-modal">
      <IonHeader className="elanco-modal-header">
        <IonToolbar>
          <IonButtons>
            <button className="elanco-modal-close" onClick={onDismiss}>
              <IonIcon color="secondary" slot="end" icon={closeCircle} />
            </button>
          </IonButtons>
          <IonSearchbar
            className="elanco-modal-search"
            onIonChange={throttleTypeAhead}
            placeholder="Search Clinic Name"
            showClearButton="focus"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList className="elanco-modal-list" lines="none">
          <IonItem
            onClick={() => {
              onDismiss();
              onSelectItem({
                clinic_name: 'Relief Vet',
                crm_id: '',
                zip: '',
                state_cd: '',
                address_1: '',
                city: '',
                created_at: '',
                updated_at: '',
              });
            }}>
            Relief Vet
          </IonItem>
          <IonItem
            onClick={() => {
              onDismiss();
              onSelectItem({
                clinic_name: 'Student',
                crm_id: '',
                zip: '',
                state_cd: '',
                address_1: '',
                city: '',
                created_at: '',
                updated_at: '',
              });
            }}>
            Student
          </IonItem>
          <IonItem
            onClick={() => {
              onDismiss();
              onSelectItem({
                clinic_name: 'Add My Own Clinic',
                crm_id: '',
                zip: '',
                state_cd: '',
                address_1: '',
                city: '',
                created_at: '',
                updated_at: '',
              });
            }}>
            Add My Own Clinic
            <IonIcon color="secondary" className="ml-2" icon={addCircle} />
          </IonItem>
          <IonItem>
            <hr className="elanco-divider" />
          </IonItem>
          {clinics.map((clinic: IClinic) => (
            <IonItem
              key={clinic.crm_id}
              className="ion-text-capitalize"
              onClick={() => {
                onDismiss();
                onSelectItem(clinic);
              }}>
              <IonText>
                {clinic.clinic_name.toLocaleLowerCase()}
                <div className="elanco-modal--address">
                  {clinic.address_1.toLocaleLowerCase()},{' '}
                  {clinic.city.toLocaleLowerCase()},{' '}
                  {clinic.state_cd.toLocaleUpperCase()}{' '}
                  {clinic.zip.toLocaleLowerCase()}
                </div>
              </IonText>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

const ClinicModal: React.FC<{
  data: any;
  onChange: (param: Object) => void;
  onBlur: () => void;
  value?: IClinic;
}> = ({ onChange, onBlur, value, data = [] }) => {
  const { showConfirmAddress } = useConfirmAddress();
  const handleClick = (clinic: IClinic) => {
    // if clinic is custom dont display modal
    if (
      clinic.clinic_name === 'Add My Own Clinic' ||
      clinic.clinic_name === 'Student' ||
      clinic.clinic_name === 'Relief Vet'
    ) {
      onChange({
        clinic_name: clinic.clinic_name,
      });
      return;
    }

    const addressClass =
      clinic.address_1.length > 23 ? 'item--clinic-address' : '';

    // create modal description
    const modalDescription = `
      <span class="${addressClass}"> 
        ${clinic.address_1.toLocaleLowerCase()} <br />
      </span>
      ${clinic.city.toLocaleLowerCase()},
      ${clinic.state_cd.toLocaleUpperCase()}
      ${clinic.zip}
    `;

    // crea figmte modal and wait for response
    const modal = showConfirmAddress(modalDescription);
    modal.then((isConfirmed) => {
      if (isConfirmed) {
        onChange({ crm_id: clinic.crm_id, clinic_name: clinic.clinic_name });
      } else {
        onChange({ clinic_name: '' });
      }
    });
  };

  const handleDismiss = () => {
    dismiss();
  };

  const [present, dismiss] = useIonModal(ClinicModalBody, {
    onDismiss: handleDismiss,
    onSelectItem: handleClick,
    clinicNames: data,
  });

  return (
    <div className="elanco--item">
      <label id="clinic">
        Clinic Name <IonText color="danger">*</IonText>
      </label>
      <input
        type="text"
        name="clinic"
        readOnly
        autoComplete="off"
        defaultValue={value?.clinic_name}
        onBlur={onBlur}
        onClick={() => {
          present();
        }}
      />
    </div>
  );
};

export default ClinicModal;
